import React from "react";
import { Link } from "react-router-dom";
import { BsHouseDoor } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { animateScroll as scroll } from 'react-scroll';

const ServicesSection1 = () => {
  const handleLinkClick = () => {
    scroll.scrollToTop();
  };
  const { name } = useParams(); // Access the 'name' parameter from the URL

  return (
    <div>
      <div>
        <div className="bg-sections w-full h-[500px] flex justify-center ">
          <div className=" relative items-center flex flex-col justify-center shadow-white text-white drop-shadow-md hover:drop-shadow-xl">
            <h3 className="text-3xl font-bold uppercase">Industrial Coverage</h3>
            
            <div className="font-semibold text-lg pt-3 flex hover:text-gray-200">
              <Link to="/" className="cursor-pointer" onClick={handleLinkClick}>
                <BsHouseDoor size={22} />
              </Link>
              <Link to="/" className="cursor-pointer capitalize" onClick={handleLinkClick}>
                {" "}
                Home
              </Link>
              /
              <span className="text-gray-200 hover:text-gray-300 cursor-pointer capitalize">
                <Link to={`/about`} onClick={handleLinkClick}>
                  {" "}
                  {/* Use the 'name' parameter */}
                  About Us/
                </Link>{" "}
                <Link to={`/industrialcoverage/${name}`} onClick={handleLinkClick}>{name} </Link>
              </span>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesSection1;
