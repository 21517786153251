import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import { animateScroll as scroll } from "react-scroll";
import ScrollTrigger from "react-scroll-trigger";

const cardsData = [
  {
    title: "Want to Know About Us?",
    description: "Dive deep into the world of quality and consistency.",
    link: '/services',
    linkname: 'Learn about Standards'
  },
  {
    title: "Looking to get certified?",
    description: "Discover how conformity with a standard is assessed by certification bodies and others.",
    link: '/contact',
    linkname: 'Learn About Certification'
  },
  {
    title: "Buy a Toolkit",
    description: "Enhance your business with the power of ISO standards.",
    linkname: 'Visit the Store',
    link: '/toolkits'
  },
];

const YourComponent = () => {
  const [counterOn, setCounterOn] = useState(false);

  const textSpring = useSpring({
    opacity: counterOn ? 1 : 0,
    // transform: counterOn ? "translateX(0)" : "translateX(50%)",
    from: { opacity: 0, },
  });
    const handleLinkClick = () => {
      scroll.scrollToTop();
    }
  return (
    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>

    <div className="py-16 mx-5 md:mx-8 ">

      <div>
        <h1 className="font-bold text-center md:text-[34px] text-[24px] pb-8 md:pb-16">What do Standards Do?</h1>
        <p className="md:text-lg text-[19px] text-[#1B1C19] mb-16 text-center md:text-left">
          International standards elevate your everyday experiences. They ensure that the products and services you use are not just reliable and of high quality but also safe for you and our planet. Businesses guided by these standards adopt sustainable and ethical practices, contributing to a future where your purchases seamlessly blend quality with conscience. Make choices that matter — choose standards.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 justify-center mx-auto">
        {cardsData.map((card, index) => (
          <animated.div
            key={index}
            style={{
    ...textSpring,
    ...(window.innerWidth <= 1180 && window.innerWidth >= 980 && { width: '310px' }),
    ...(window.innerWidth <= 980 && window.innerWidth >= 740 && { width: '360px' }),
     ...{ width: '100%' },
  }}
            className="media cursor-pointer w-full md:w-[350px] lg:w-[390px]  mx-auto h-[280px]  text-center border-none rounded-[25px] p-4    bg-white shaddow-black/40 shadow-2xl hover:shadow-black/80 transition-all duration-300 ease-in-out  hover:shadow-2xl mt-2 flex flex-col justify-between relative"
          >
            <div>
              <h5 className="font-semibold text-[20px] md:text-[24px] pt-3 mb-[0.5rem] leading-[1.2]  cursor-pointer text-[#1B1C19]">
                {card.title}
              </h5>
              <p className="text-gray-700  text-center py-10 md:py-4 text-[18px] md:text-[20px]">{card.description}</p>
            </div>
            <Link to={card.link} onClick={handleLinkClick}>
              <div className="absolute bottom-5 left-0 right-0 flex items-center justify-center">
                <p className="text-orange-500 text-[18px] md:text-[20px] text-center font-semibold hover:underline hover:text-orange-600 transition-all duration-200 ease-in-out">{card.linkname}</p>
              </div>
            </Link>
          </animated.div>
        ))}
      </div>
    </div>
    </ScrollTrigger >

  );
};

export default YourComponent;
