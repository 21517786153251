import React from 'react'
import ClienteleSection1 from './ClienteleSection1'
import ClienteleSection2 from './ClienteleSection2'
import Layout from '../../components/Layout'

const Clientele = () => {
  return (
    <div>
         <Layout title={"MBA Solutions - Clientele "} keywords={"clients, Clientele"}>
<div className='bg-[#F2F2F2] pb-12'>
        <ClienteleSection1 />
        <ClienteleSection2 />
        </div>
        </Layout>
    </div>
  )
}

export default Clientele