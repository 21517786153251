// GetClientSection.js
import React, { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';
import axios from "axios";
import Swal from "sweetalert2";
import { FaTrash, FaEdit } from 'react-icons/fa'; // Import the edit icon
import { Link } from 'react-router-dom';


const GetClient = () => {

    const [dataa, setDataa] = useState([]);
    const [cookies, setCookie] = useCookies(['editedClient']);
  
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    // Fetch data from the server
    const fetchData = async () => {
        try {
            const response = await axios.get("https://api.mbasolutions.co/api/client/",config);
            console.log("Fetched data:", response.data);
            setDataa(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Error Getting data",
            });
        }
    };

    // Delete a team member
    const handleDelete = async (id) => {
      try {
        const confirmed = await Swal.fire({
          title: "Are you sure?",
          text: "This action cannot be undone.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        });
        if (confirmed.isConfirmed) {
          await axios.delete(
            `https://api.mbasolutions.co/api/client/delete/${id}`,
            config
          );
  
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Data has been deleted successfully",
          });
  
          fetchData();
        }
      } catch (error) {
        console.error("Error deleting data:", error);
      }
    };

    // Edit a team member
    const handleEdit = (_id) => {
      const editedClient = dataa.find((data) => data._id === _id);
      setCookie('editedClient', editedClient, { path: '/' });
      console.log(_id);
    };

    useEffect(() => {
        fetchData();
    }, []);

   
    return (
        <section className="bg-gray-100 min-h-full mb-6 w-full px-4 md:px-10 py-20">
            <div className="grid w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-4 md:px-12">
                {dataa && dataa.length > 0 ? (
                    dataa.map((item) => (
                        <div
                            key={item._id}
                            className="bg-white p-6 md:p-8  shadow-lg rounded-lg hover:shadow-2xl transition duration-300 ease-in-out relative"
                        >
                            <span
                                className="absolute top-4 right-4 cursor-pointer hover:text-red-600 transition-all ease-out duration-300 "
                                onClick={() => handleDelete(item._id)}
                            >
                                <FaTrash size={19} />
                            </span>
                            <Link to='/updateclient'>
                            <span
                                className="absolute top-4 left-4 cursor-pointer hover:text-blue-600 transition-all ease-out duration-300 "
                                onClick={() => handleEdit(item._id)}
                            >
                                <FaEdit size={19} />
                            </span>
                            </Link>
                            <img
                                src={`https://api.mbasolutions.co${item.image}`}
                                alt={item.name}
                                className="mx-auto w-48 h-48 rounded mb-4"
                            />
                            <h3 className="text-2xl font-semibold text-gray-800 text-center">
                                {item.name}
                            </h3>
                            </div>
                    ))
                ) : (
                    <p>Loading...</p>
                )}
            </div>
        </section>
    );
};

export default GetClient;
