import React from 'react'
import JobSection2 from './JobSection2'
import JobSection1 from './JobSection1'
import Layout from '../../components/Layout'
import JobSection3 from './JobSection3'

const Jobs = () => {
  return (
    <div >
        <Layout title={"MBA Solutions - Jobs "} keywords={"Careers, Jobs, Get in Touch, Reach Us, MBA Solutions ,MBA Solutions Jobs, Jobs form, "}>
    <div className='bg-[#F2F2F2]'>
        <JobSection1 />
        <JobSection3 />
        <JobSection2 />
        </div>
        </Layout>
    </div>
  )
}

export default Jobs