import React from 'react'
import Layout from '../../components/Layout'
import TrackingSection1 from './TrackingSection1'
import TrackingSection2 from './TrackingSection2'

const Tracking = () => {
  return (
    <div>
       <Layout title={"MBA Solutions - Tracking "} keywords={"Track, Tracking, MBA Solutions ,MBA Solutions tracking, Contact Information, track document"}>
<div className='bg-[#F2F2F2] pb-32'>
<TrackingSection1 />
<TrackingSection2 />
</div>
      </Layout>

    </div>
  )
}

export default Tracking