import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { ServicesData } from "./ServicesData"; 
import ServicesSection1 from './ServicesSection1';
import Navbar from '../../components/Navbar';
import Layout from '../../components/Layout';
import ContactSection4 from '../contact/ContactSection4'
const ServiceInnerPage = () => {
  const { servicepage2data } = ServicesData;
  const { name } = useParams(); 
  const [matchedData, setMatchedData] = useState(null);

  useEffect(() => {
    const lowercaseTrimmedName = name.trim().toLowerCase();
    const lowercaseTrimmedData = servicepage2data.map((service) => service.name.trim().toLowerCase());
    const matchedIndex = lowercaseTrimmedData.indexOf(lowercaseTrimmedName);
    if (matchedIndex !== -1) {
      setMatchedData(servicepage2data[matchedIndex]);
    }
console.log(lowercaseTrimmedData)

  }, [name, servicepage2data]);
  
console.log(name)

  return (
    <Layout title={"MBA Solutions - Services-certification "}>
     
      <ServicesSection1 />
     <div className='mt-[33rem] mx-7 lg:mx-14 mb-16'>
      {matchedData && (
        <>
        <div className='text-center'>
          <h2 className='title font-bold text-[35px] '>{matchedData.name}</h2>
          <p className='py-10 text-[18px] text-gray-800'>{matchedData.shortdes}</p>
          </div>
          {matchedData.points && (
            <ul className='list-decimal'>
              {matchedData.points.map((point) => (
                <li key={point.code}>
                  <h3 className='font-semibold py-3'>{point.title}</h3>
                  <p className='leading-8'>{point.description}</p>
                </li>
              ))}
            </ul>
          )}
        </>
      )}
    </div>
    <ContactSection4 />

    </Layout>
  )
}

export default ServiceInnerPage
