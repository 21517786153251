import React, { useRef, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AddServiceData = () => {
  const quillRef = useRef(null);
  const [formData, setFormData] = useState({
    name: "",
    paragraph: "",
    image: null,
  });

  const handleInputChange = (value) => {
    // Update only the paragraph field in the state
    setFormData({ ...formData, paragraph: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, image: file });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a FormData object to send the data
    const data = new FormData();
    data.append("name", formData.name);
    data.append("paragraph", formData.paragraph);
    data.append("image", formData.image);

    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const url = "https://api.mbasolutions.co/api/categories/upload";
      const response = await axios.post(url, data, config);

      // Show a success message using SweetAlert2
      Swal.fire({
        icon: "success",
        title: "Service created successfully!",
        showConfirmButton: false,
        timer: 1500,
      });
      // Reset the form fields to their initial values
      setFormData({
        name: "", // Reset to the default value
        paragraph: "",
        image: null,
      });
    } catch (error) {
      // Handle errors, e.g., display an error message
      console.error("Error adding data:", error);

      // Show an error message using SweetAlert2
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error adding data",
      });
    }
  };

  return (
    <>
      <div className="mx-auto">
        <form
          onSubmit={handleSubmit}
          className="w-full flex flex-col justify-center mt-10"
        >
          <div className="mx-auto md:w-[75%]">
            <label htmlFor="name" className="block text-[20px] pb-4">
              Name
            </label>
            <input
              name="name"
              id="name"
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              value={formData.name}
              className="h-12 w-[90%] border px-2 hover:outline-orange-500"
            />
          </div>
          <div className="mx-auto md:w-[75%]">
            <label className="block text-[20px] pt-5 pb-2">Description:</label>
            <ReactQuill
              theme="snow"
              placeholder="Write Description....."
              value={formData.paragraph}
              onChange={handleInputChange}
              className="w-[90%] py-1 h-40 max-h-fit"
              ref={quillRef}
              modules={AddServiceData.modules}
              formats={AddServiceData.formats}
            />
          </div>
          <div className="mx-auto md:w-[75%] mt-36">
            <label htmlFor="image" className="block text-[20px] pb-4">
              Choose Image:
            </label>
            <input
              type="file"
              name="image"
              id="image"
              onChange={handleImageChange}
              className="  outline-none h-10 m-2  text-md text-center "
            />
            <br />
          </div>
          <div>
            <button
              type="submit"
              className="block text-[20px] mt-4 p-2 bg-orange-500 text-white hover:bg-orange-700 rounded-md mx-auto  "
            >
              Add Data
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

AddServiceData.modules = {
  toolbar: [
    [{ font: [] }],
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    ["link", "image"],
    ["code-block"],
    ["clean"],
  ],
};

AddServiceData.formats = [
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "list",
  "bullet",
  "align",
  "color",
  "background",
  "link",
  "image",
  "code-block",
];

export default AddServiceData;
