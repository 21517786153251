import React from 'react';
import { Link } from 'react-router-dom';
const Cancel = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-red-100">
      <div className="bg-white py-10 px-6 rounded-lg shadow-lg text-center">
        <svg
          className="w-16 h-16 text-red-500 mx-auto mb-4"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
        <h2 className="text-2xl font-bold mb-2">Payment Canceled</h2>
        <p className="text-gray-600 mb-4 max-w-[260px]">Your payment has been canceled. If you have any questions, please contact support.</p>
        <Link to='/toolkits'>
        <button className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600">
          Try Again
        </button>
        </Link>
      </div>
    </div>
  );
};

export default Cancel;
