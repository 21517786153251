import axios from "axios";
import React, { useState, useEffect } from "react";
import PecbSection3responsive from "./PecbSection3responsive";
import EnrollmentForm from "./EnrollmentForm";
import { useParams } from 'react-router-dom';
const PecbSection3 = () => {
  const [course, setCourse] = useState([]);
  const [isEnrollmentFormOpen, setIsEnrollmentFormOpen] = useState(false);

  const { itemName } = useParams();
  console.log("itemName:", itemName);

  
   
  
  useEffect(() => {
    axios.get(`https://api.mbasolutions.co/api/getcoursedata`).then((res) => {
      console.log(res.data);
      setCourse(res.data);
    });
  }, []);
  const handleEnrollClick = () => {
    // Open the enrollment form
    setIsEnrollmentFormOpen(true);
  };
  const filteredCourses = course.filter(course => course.name === itemName);

  return (
    <div className=" my-10 ">
 <h2 className='text-[30px] font-bold text-shadow my-20 text-center'>{itemName}</h2>

      <PecbSection3responsive />
      {filteredCourses.length === 0 ? (
        <p className="text-center text-2xl mt-8 flex items-center justify-center pb-4">No Trainings are Started Now</p>
      ) : (
        filteredCourses.map((course) => (
          <>
         
      <div className="overflow-x-auto hidden lg:block mx-7 md:mx-14">
        <table className="min-w-full border w-full">
          <thead className="bg-purple-500 text-white">
            <tr>
              <th className="py-4">Start Date</th>
              <th>End Date</th>
              <th>Start - End Time</th>
              <th>Batch Type</th>
              <th>Fee</th>
              <th>Training Mode</th>
              <th>Batch Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredCourses.map((course) => (
              <tr key={course.id} className="border-b">
                <td className="pl-4 py-3 md:pl-10">{course.startdate}</td>
                <td className="pl-4 md:pl-10">{course.enddate}</td>
                <td className="pl-4 md:pl-10">{course.startendtime}</td>
                <td className="pl-4 md:pl-10">{course.batchtype}</td>
                <td className="pl-4 md:pl-10">{course.fee}</td>
                <td className="pl-4 md:pl-10">{course.trainingmode}</td>
                <td className="pl-4 md:pl-10">{course.batchstatus}</td>
                <td>
                  {course.enroll ? (
                   <div className="py-[0.3rem]  w-32 flex justify-center hover:bg-orange-700 cursor-pointer mx-auto rounded-md bg-orange-500 text-white text-md" onClick={handleEnrollClick}>
                      Enroll Now
                    </div>
               
                  ) : (
                    <div className="py-[0.3rem] w-32 flex justify-center hover:bg-purple-700 cursor-pointer mx-auto rounded-md bg-purple-500 text-white text-md">
                      Enroll Closed
                    </div>
                  )}
                </td>
              </tr>
            ))}{isEnrollmentFormOpen && (
              <EnrollmentForm onClose={() => setIsEnrollmentFormOpen(false)} />
            )}

          </tbody>
        </table>
        
      </div>
      </>
        ))
      )}
    </div>
  );
};

export default PecbSection3;
