// AdminBlogList.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from "react-scroll";

const AdminBlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [cookies, setCookie] = useCookies(['editedBlog']);
  
  const fetchBlogs = async () => {
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get('https://api.mbasolutions.co/api/blogs/get', config);
      setBlogs(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error Getting data',
      });
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const confirmed = await Swal.fire({
        title: 'Are you sure?',
        text: 'This action cannot be undone.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      });
      if (confirmed.isConfirmed) {
        await axios.delete(`https://api.mbasolutions.co/api/blogs/delete/${id}`, config);

        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Data has been deleted successfully',
        });

        fetchBlogs();
      }
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  const handleEdit = (_id) => {
    setCookie('editedBlogId', _id, { path: '/' },);
    console.log(_id)
    scroll.scrollToTop();
  };
  
  const filteredBlogs = blogs.filter(
    (blog) => blog.name && blog.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className=" mx-auto mt-8 ">
      <input
        type="text"
        placeholder="Search blogs"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="w-[94%] py-2 mx-6 px-4 mb-4 border border-gray-300 rounded-md"
      />
      <div className="mx-6 grid grid-cols-1 md:grid-cols-2 gap-6 lg:grid-cols-3 ">
        {filteredBlogs.map((blog) => (
          <div key={blog._id} className="bg-white p-6 rounded-lg shadow-lg flex justify-center flex-col items-center  hover:shadow-2xl">
            <h3 className="text-xl font-semibold mb-4">{blog.name}</h3>
            <img
              src={`https://api.mbasolutions.co${blog.image}`}
              alt="Blog Image"
              className="mb-4 max-w-full h-[350px] rounded-md"
            />
            <div
              dangerouslySetInnerHTML={{ __html: blog.paragraph }}
              className="text-gray-700"
            />
            <div className="mt-4 flex space-x-4">
              <button
                onClick={() => handleDelete(blog._id)}
                className="bg-red-500 text-white px-4 py-2  rounded-md hover:bg-red-600 transition duration-300"
              >
                Delete
              </button>
              <Link to="/updateblog">
                <button
                  onClick={() => handleEdit(blog._id)}
                  className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300"
                >
                  Edit
                </button>
              </Link>
            </div>

          </div>
        ))}
      </div>

    </div>
  );
};

export default AdminBlogList;
