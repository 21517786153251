// TeamSection.js
import React, { useEffect, useState } from "react";
import { data } from '../../data';
import axios from "axios";
import vid from '../../assets/Team/atif.mp4'

const Team = () => {
    const [dataa, setDataa] = useState([]);

    // Fetch data from the server
    const fetchData = async () => {
        try {
            const response = await axios.get("https://api.mbasolutions.co/api/team/");
                       setDataa(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
           
        }
    };
    
    useEffect(() => {
        fetchData();
    }, []);

   
    
  return (
    <section className="bg-gray-100 mb-6 w-full  px-4 md:px-10   py-20">
      <div className="  text-center  ">
        <h2 className="title text-4xl font-semibold  mb-6  pb-3">
          Our Amazing Team
        </h2>
        <p className="text-center text-xl  text-[#3a353a66] lg:pb-20 pb-6  ">The Talented People Behind the Scenes of the Organization</p>
     
     
     
       <video src={vid}  autoPlay muted preload loop className=""/>

        {/* Other Team Members Row */}
        <div className="grid mt-24 w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-4 md:px-12">
                {dataa && dataa.length > 0 ? (
                    dataa.map((item, index) => (
                        <div
                            key={index}
                            className="bg-white p-6 md:p-8 cursor-pointer shadow-lg rounded-lg hover:shadow-2xl transition duration-300 ease-in-out"
                        >
                            <img
                                src={`https://api.mbasolutions.co${item.image}`}
                                alt={item.name}
                                className="mx-auto w-48 h-48 rounded-full mb-4"
                            />
                            <h3 className="text-2xl font-semibold text-gray-800">
                                {item.name}
                            </h3>
                            <p className="text-gray-600">{item.position}</p>
                            <p className="text-gray-700 py-7">{item.paragraph}</p>
                        </div>
                    ))
                ) : (
                    <p>Loading...</p> 
                )}
            </div>
      </div>
    </section>
  );
};
export default Team;
