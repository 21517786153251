import React, { useState } from "react";
import Swal from "sweetalert2"; // Import SweetAlert2
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { loadStripe } from "@stripe/stripe-js";

const PaymentForm = ({ price }) => {
  const [cardNumber, setCardNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvv, setCVV] = useState("");
  const [name, setName] = useState("");

// const data=[{
//   toolkitname:new URLSearchParams(window.location.search).get("name")|| "",
//   price:new URLSearchParams(window.location.search).get("price")|| ""
// }]console.log(data)

 


  return (
    <>
    <Navbar />
    <div className="max-w-md mx-auto my-16 p-6 border rounded-lg shadow-lg">
      <h2 className="text-2xl font-semibold mb-4">Payment Details</h2>
      <form>
        <div>
      <label className="block text-gray-700 text-sm font-bold mb-2">
    Amount (Rupees)
  </label>
  <input
    type="text"
    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    placeholder="Enter the payment amount in Rupees"
    value={` ${new URLSearchParams(window.location.search).get("name") || ""}`}
    readOnly 
  />
</div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Card Number
          </label>
          <input
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="1234 5678 9012 3456"
            value={cardNumber}
            onChange={(e) => setCardNumber(e.target.value)}
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Expiry Date
            </label>
            <input
              type="text"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="MM/YY"
              value={expiry}
              onChange={(e) => setExpiry(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              CVV
            </label>
            <input
              type="text"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="123"
              value={cvv}
              onChange={(e) => setCVV(e.target.value)}
            />
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Cardholder Name
          </label>
          <input
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="John Doe"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="mb-4">
  <label className="block text-gray-700 text-sm font-bold mb-2">
    Amount (Rupees)
  </label>
  <input
    type="text"
    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    placeholder="Enter the payment amount in Rupees"
    value={`$ ${new URLSearchParams(window.location.search).get("price") || ""}`}
    readOnly // Make this input read-only
  />
</div>
        <button
          type="button"
          className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          // onClick={handlePayment}
        >
          Make Payment
        </button>
      </form>
    </div>
    </>
  );
};

export default PaymentForm;
