import React, { useState, useEffect } from 'react';

const Cookies = () => {
  const [showCookies, setShowCookies] = useState(false);

  // Check if the user has accepted cookies using document.cookie
  useEffect(() => {
    const acceptedCookies = document.cookie.includes('acceptedCookies=true');
    if (!acceptedCookies) {
      // Delay showing the cookie notification by 2 seconds
      setTimeout(() => {
        setShowCookies(true);
      }, 2000);
    }
  }, []);

  const handleAcceptCookies = () => {
    // Update the state and set a cookie to store the acceptance
    setShowCookies(false);
    document.cookie = 'acceptedCookies=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/';
  };

  return (
    <div
      className={`Georgian fixed bottom-4 right-4 bg-purple-500 text-white p-4 z-[20000] w-[90%] md:w-[350px] rounded-lg ${
        showCookies ? 'block' : 'hidden'
      }`}
    >
      {/* new commit has added  */}
      <h2 className='text-[18px]'>This Website uses Cookies.</h2>
      <p className='py-2 text-[12px]'>
        We use cookies to analyze website traffic and optimize your website experience. By accepting our use of cookies, your data will be aggregated with all other user data.
      </p>
      <button className='bg-white text-black text-center py-2 w-[100%] rounded-full' onClick={handleAcceptCookies}>
        Accept
      </button>
    </div>
  );
};

export default Cookies;
