import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Section1 from './Section1';
import ServiceForm from '../ServiceForm';
import Layout from '../../../components/Layout';
import ContactSection4 from '../../contact/ContactSection4';
import ServicesSection1 from '../ServicesSection1';
import Cookies from 'js-cookie';
import { useCookies } from 'react-cookie';

const Auditing = () => {
  const [serviceName, setServiceName] = useState('');
  const [cookies] = useCookies(["clickedServiceId"]);

  useEffect(() => {
    const fetchData = async () => {
      try {
       
        const response = await axios.get(`https://api.mbasolutions.co/api/categories/get/${cookies.clickedServiceId}`);
        // Assuming the API response has a 'name' property
        setServiceName(response.data.name);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []); // The empty dependency array ensures that this effect runs once when the component mounts

  return (
    <div>
      <Layout title={`MBA Solutions - ${serviceName} Service`} keywords={`Auditing, Services MBA Auditing, Mba Solutions Service Offerings`}>
        <ServicesSection1 name={serviceName} />
        <Section1 />
       
        <div className='mt-10'>
          <ContactSection4 />
        </div>
      </Layout>
    </div>
  );
};

export default Auditing;
