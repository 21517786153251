import React, { useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { Link } from "react-router-dom";

const AddTrackingData = () => {
  const [formData, setFormData] = useState({
    name: "",
    serialnumber: "",
    image: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, image: file });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a FormData object to send the data
    const data = new FormData();

    data.append("name", formData.name);
    data.append("serialnumber", formData.serialnumber);
    data.append("image", formData.image);

    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const url = "https://api.mbasolutions.co/api/tracking/upload";
      const response = await axios.post(url, data, config);

      // Show a success message using SweetAlert2
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Data added successfully",
      });

      // Reset the form fields to their initial values
      setFormData({
        id: "", // Reset to an appropriate default value
        name: "",
        file: null,
        serial: "",
      });

    } catch (error) {
      // Handle errors, e.g., display an error message
      console.error("Error adding data:", error);

      // Show an error message using SweetAlert2
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error adding data",
      });
    }
  };

  return (
    <>
      <div className="mx-auto rubik">
       
        <form
          onSubmit={handleSubmit}
          className="w-full flex flex-col justify-center"
        >
          <div className="mx-auto">
            <label
              htmlFor="serialnumber"
              className="block font-semibold text-[18px] pb-4"
            >
              Enter Serial Number:
            </label>
            <input
              type="text"
              name="serialnumber"
              id="serialnumber"
              onChange={handleInputChange}
              value={formData.serialnumber}
              className="h-10 mb-3 w-[570px] border hover:outline-purple-500"
            />
          </div>
          <div className="mx-auto">
            <label
              htmlFor="name"
              className="block font-semibold text-[18px] pb-4"
            >
              Enter Name:
            </label>
            <input
              type="text"
              name="name"
              id="name"
              onChange={handleInputChange}
              value={formData.name}
              className="h-12 w-[570px] border hover:outline-purple-500"
            />
          </div>
          <div className="mx-auto w-[570px] mt-12">
            <label
              htmlFor="file"
              className="block text-[20px] font-semibold pb-4"
            >
              Choose File:
            </label>
            <input
              type="file"
              name="image"
              id="image"
              onChange={handleImageChange}
              className="outline-none h-10 m-2 text-md text-center"
            />
            <br />
          </div>

          <div>
            <button
              type="submit"
              className="block text-[20px] p-2 bg-purple-500 text-white hover:bg-purple-700 rounded-md mx-auto  "
            >
              Add Data
            </button>
          </div>
        </form>

       
      </div>
    </>
  );
};

export default AddTrackingData;
