import React from 'react'
import Layout from '../../../components/Layout'
import Section1 from './Section1'
import ServiceForm from '../ServiceForm'
import ContactSection4 from '../../contact/ContactSection4'
import ServicesSection1 from '../ServicesSection1'
const Certification = () => {
  return (
 
 <div> <Layout title={"MBA Solutions - Certification_Service "} keywords={"Certification,Services, MBA Certifications,Mba Solutions Service Offerings"}>
   <ServicesSection1 name={"Certification"}/>

 <Section1 />
 {/* <ServiceForm /> */}

 <div>
  <ContactSection4 />
 </div>
    </Layout></div>
  )
}

export default Certification
