import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { FormAuthenticationmessage } from "../contact/FormAuthenticationmessage";
import axios from "axios";
import Swal from "sweetalert2";
const EnrollmentForm = ({ onClose }) => {
  const [isTextareaFocused, setIsTextareaFocused] = useState(false);
  const validate = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is required"),
    phone: Yup.string().required("Phone No is required"),
    message: Yup.string(),
    
  });

  return (
    <div>
      <Formik
        initialValues={{
          name: "",
          email: "",
          phone: "",
          comment: "",
        }}
        validationSchema={validate}
        onSubmit={async (values, { resetForm }) => {
          try {
            await validate.validate(values, { abortEarly: false }); // Run validation for all fields

            // If the form is valid, proceed with submitting the data
            resetForm();
            const response = await axios.post(
              "https://api.mbasolutions.co/trainingForm",
              values
            );
          

            // Show success alert
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "Data has been sent successfully",
            });
          } catch (error) {
            console.error(error);

            // If the form is not valid, show an alert error
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Network Error.Please Try Again Later.",
            });
          }
        }}
      >
        {(formik) => (


    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded-md shadow-lg md:w-[65%] w-[85%]">
        <h2 className="text-2xl font-bold mb-4">Enrollment Form</h2>
        <Form >
          <div className="mb-4">
            <FormAuthenticationmessage
              type="text"
              id="name"
              name="name"
              placeholder="Name"
              className="w-full border rounded-md px-3 py-2 focus:outline-none focus:border-purple-500 shadow-md hover:shadow-purple-500"
              
            />
          </div>
          <div className="mb-4">
            <FormAuthenticationmessage
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              className="w-full border rounded-md px-3 py-2 focus:outline-none focus:border-purple-500 shadow-md hover:shadow-purple-500"
              
            />
          </div>
          <div className="mb-4">
            <FormAuthenticationmessage
              type="phone"
              id="phone"
              name="phone"
              placeholder="Phone No."
              className="w-full border rounded-md px-3 py-2 focus:outline-none focus:border-purple-500 shadow-md hover:shadow-purple-500"
              
            />
          </div>
          <div className="mb-4 relative">
            <FormAuthenticationmessage
             textarea
              id="comment"
              name="comment"
              placeholder="Comment"
              className={`w-full border rounded-md px-3 py-2 shadow-md hover:shadow-purple-500 focus:outline-none ${
                isTextareaFocused
                  ? "border-purple-500 shadow-md"
                  : "border-gray-300"
              }`}
              onFocus={() => setIsTextareaFocused(true)}
              onBlur={() => setIsTextareaFocused(false)}
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="mr-4 px-4 py-2 bg-gray-400 hover:bg-gray-500 text-white rounded-md focus:outline-none"
            >
              Close
            </button>
            <button
               type="submit"
              className="px-4 py-2 bg-purple-500 hover:bg-purple-600 text-white rounded-md focus:outline-none"
            >
              Submit
            </button>
          </div>
        </Form>
      </div>
    </div>
    )}
    </Formik>
  </div>
  );
};

export default EnrollmentForm;
