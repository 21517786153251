import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Layout from '../../components/Layout';

const NewBlogPage = () => {
  const { specificBlogId } = useParams();
  const [blogData, setBlogData] = useState(null);
  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        const response = await axios.get(`https://api.mbasolutions.co/api/blogs/getsingle/${specificBlogId}`);
        setBlogData(response.data);
      } catch (error) {
        console.error('Error fetching blog details:', error);
        
      }
    };

    fetchBlogDetails();
  }, [specificBlogId]);

  return (
    <div>
      <Layout>
        <div className='bg-[#F2F2F2] py-20'>
      {blogData ? (
        
        <>
        <div className='flex justify-center items-center'>
          <img
            src={`https://api.mbasolutions.co${blogData.image}`}
            alt={blogData.name}
            className="max-w-full mb-4 rounded-lg max-h-[500px]  "
          />
          </div>
          <h2 className="text-3xl font-bold mb-4 text-center mt-8 uppercase">{blogData.name}</h2>

          <div dangerouslySetInnerHTML={{ __html: blogData.paragraph }} className="text-gray-700 mx-10 my-10 " />
        </>
      ) : (
        <p>Loading...</p>
      )}
      </div>
      </Layout>

    </div>
  );
};

export default NewBlogPage;
