import React from "react";
import Services from "../IndustrialCoverage/Industrial";

const IndustrialCoverage = () => {
  return (
    <div className="bg-[#F2F2F2]">
      <Services />
    </div>
  );
};

export default IndustrialCoverage;
