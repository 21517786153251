import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { useCookies } from "react-cookie";

const Section1 = () => {
  const [auditingData, setAuditingData] = useState(null);
  const [cookies] = useCookies(["clickedServiceId"]);
 
  useEffect(() => {
    // Make the Axios GET request to fetch data based on the extracted id
    Axios.get(`https://api.mbasolutions.co/api/categories/get/${cookies.clickedServiceId}`)
      .then((response) => {
        console.log(response.data);
        setAuditingData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  // Additional useEffect to log auditingData.paragraph when auditingData changes
  useEffect(() => {
    if (auditingData) {
      console.log(auditingData.paragraph);
    }
  }, [auditingData]);

  return (
    <div className='my-16 mt-16 md:mx-10 mx-4 '>
      {auditingData ? (
        <div>
          <h1 className='text-[32px] font-bold drop-shadow-lg text-center'>{auditingData.name}</h1>
          <div className={`flex justify-center item-center ${auditingData.image ? 'my-20' : 'mb-20'}`}>
            <img src={`https://api.mbasolutions.co${auditingData.image}`} className="max-w-full rounded-md mx-auto max-h-[70vh]" alt='' />
          </div>
          {auditingData.paragraph && (
            <div dangerouslySetInnerHTML={{ __html: auditingData.paragraph }} className="text-gray-700 mx-10 my-10 " />
          )}
        </div>
      ) : (
       <div></div>
      )}
    </div>
  );
}

export default Section1;
