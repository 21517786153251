import React from 'react'
import ServiceForm from '../ServiceForm'
import Section1 from './Section1'
import Layout from '../../../components/Layout'
import ContactSection4 from '../../contact/ContactSection4'
import ServicesSection1 from '../ServicesSection1'

const SkillBuilding = () => {
  return (
    <div> 
    <Layout title={"MBA Solutions - Skill-building--service "} keywords={"Skill-building,Services, MBA Skill-building ,Mba Solutions Service Offerings"}>
   <ServicesSection1 name={"Skill Building"}/>
    <Section1 />

<div className='my-20'>
    <ContactSection4 />
    </div>
  </Layout></div>
  )
}

export default SkillBuilding