import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

const UpdateClient = () => {
  const [name, setName] = useState('');
    const [fileName, setFileName] = useState('');
  const [image, setImage] = useState(null);
  const [cookies] = useCookies(['editedClient']);

  useEffect(() => {
    if (cookies.editedClient) {
      const { name, image } = cookies.editedClient;
      setName(name);
      setFileName(image ? image.split('/').pop() : '');
    }
  }, [cookies.editedClient]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setFileName(file.name);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const formData = new FormData();
      formData.append('name', name);
     
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      console.log(`Id is ${cookies.editedClient._id}`)
  
      if (image) {
        formData.append('image', image);
      }
  // console.log(name)
      // Replace with your backend API endpoint
      const apiUrl = `https://api.mbasolutions.co/api/client/update/${cookies.editedClient._id}`;
      await axios.put(apiUrl,formData,config);
  
      Swal.fire({
        icon: 'success',
        title: 'Client member updated successfully!',
        showConfirmButton: false,
        timer: 1500,
      });
        window.location = "/admindashboard";
    } catch (error) {
      console.error('Error updating Client member:', error);
    
      // Log complete error object
      console.error('Complete error object:', error.response);
    
      Swal.fire({
        icon: 'error',
        title: 'Error updating Client member',
        text: 'Please try again.',
      });
    }
  }
    

  return (
    <>
      <div className="mx-3 md:mx-10">
        <form
          onSubmit={handleSubmit}
          className="w-full flex flex-col justify-center"
        >
          <div className="mx-auto">
            <label
              htmlFor="name"
              className="block text-[20px] py-4 font-semibold"
            >
              {" "}
              Name
            </label>
            <input
              name="name"
              id="name"
              onChange={(e) => setName(e.target.value)}
              value={name}
              className="rounded-md h-12 px-1 md:w-[570px] border hover:outline-purple-500"
            />
          </div>
         
          <div className="mx-auto md:w-[570px] mt-12">
            <label
              htmlFor="image"
              className="block text-[20px] py-4 font-semibold"
            >
              Choose Image:
            </label>

            <input
              type="file"
              name="image"
              id="image"
              onChange={handleImageChange}
              className="  outline-none h-10 m-2  text-md text-center "
            />
            <br />
            {fileName}
          </div>
          <div>
       
            <button
              type="submit"
              className="block text-[20px] p-2 my-4 bg-purple-500 text-white hover:bg-purple-700 rounded-md mx-auto  "
            >
              Update Data
            </button>

          </div>
        </form>
      </div>
    </>
  );
};

export default UpdateClient;
