import React, { useState, useEffect, useRef } from 'react';
import logo from '../../assets/Logo.png';

const TrackingSection1 = () => {
  const [loaded, setLoaded] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    const handleLoadedData = () => {
      setLoaded(true);
    };

    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener('loadeddata', handleLoadedData);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('loadeddata', handleLoadedData);
      }
    };
  }, []);

  return (
    <div className="w-full relative ">
      {!loaded && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <img src={logo} alt="Loading..." className="animate-ping lg:w-auto lg:h-auto w-[140px] h-[90px]" /> {/* Stylish loader using logo */}
        </div>
      )}
      <video
        ref={videoRef}
        autoPlay
        muted
        loop
        preload="auto"
        className={`${loaded ? 'loaded' : ''} bg-cover object-contain `}
        width="100%"
      >
        <source src="https://api.mbasolutions.co/api/video/tracking" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default TrackingSection1;
