// AdminBlogUpdate.jsx
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import ReactQuill from 'react-quill';
import { useDropzone } from "react-dropzone"; // Import useDropzone
import 'react-quill/dist/quill.snow.css';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import { Link } from 'react-router-dom';
import DashboardNav from '../Dashoard/Navbar/DashboardNav';
import Dashboard from '../Dashoard/Dashboard';

const AdminBlogUpdate = () => {
  const [title, setTitle] = useState('');
  const [value, setValue] = useState('');
  const [fileName, setFileName] = useState('');
  const [image, setImage] = useState(null);
  const quillRef = useRef(null);
  const [cookies] = useCookies(['editedBlogId']); // Update cookie name to match
  
  useEffect(() => {
    if (cookies.editedBlogId) { // Check for editedBlogId
      const fetchData = async () => {
        try {
          const response = await axios.get(`https://api.mbasolutions.co/api/blogs/getsingle/${cookies.editedBlogId}`);
          const { name, paragraph, image } = response.data;
          setTitle(name);
          setValue(paragraph);
          setFileName(image ? image.split('/').pop() : '');
        } catch (error) {
          console.error('Error fetching blog data:', error);
        }
      };
  
      fetchData();
    }
  }, [cookies.editedBlogId]);
  
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleImageDrop = (acceptedFiles) => {
    const selectedImage = acceptedFiles[0];
    setImage(selectedImage);
    setFileName(selectedImage.name);
  };

  const handleBody = (e) => {
    setValue(e);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleImageDrop,
  });

  const handleUpdateBlog = async () => {
    try {
      const formData = new FormData();
      formData.append('name', title);
      formData.append('paragraph', value);

      const token = localStorage.getItem('token');
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // Check if image is selected before appending
      if (image) {
        formData.append('image', image);
      }

      const apiUrl = `https://api.mbasolutions.co/api/blogs/update/${cookies.editedBlogId}`;
      await axios.put(apiUrl, formData, config);

      Swal.fire({
        icon: 'success',
        title: 'Blog updated successfully!',
        showConfirmButton: false,
        timer: 1500,
      });

      window.location = "/admindashboard";
    } catch (error) {
      console.error('Error updating blog:', error);

      Swal.fire({
        icon: 'error',
        title: 'Error updating blog',
        text: 'Please try again.',
      });
    }
  };

  return (
    <>

      <div className="rubik w-[90%] md:w-[80%] mt-32 mx-auto">
        <h2 className="text-[30px] drop-shadow-lg my-8 font-bold">Update Blog</h2>
        <div className="md:w-[70%] flex flex-col">
          <label className="text-[22px] font-bold mb-2">Title:</label>
          <input
            type="text"
            value={title}
            onChange={handleTitleChange}
            className="w-[90%] py-1 h-16 border border-gray-400"
          />
        </div>
        <div className="md:w-[70%] flex flex-col mt-4">
          <label className="text-[22px] font-bold mb-2">Description:</label>
          <ReactQuill
            theme="snow"
            placeholder="Write Description....."
            value={value}
            onChange={handleBody}
            className="w-[90%] py-1 h-40 max-h-fit"
            ref={quillRef}
          />
        </div>
        <div className="md:w-[50%] flex flex-col mt-24">
          <label className="text-[22px] font-bold mb-2">Image:</label>
          <div {...getRootProps()} style={dropzoneStyles} className="h-40">
            <input {...getInputProps()} className="" />

            <p className="justify-center items-center flex my-12">
              Drag 'n' drop an image here, or click to select one
            </p>
          </div>
          <p className="mb-4 text-gray-800">{fileName}</p>
        </div>

        <button
          className="text-[22px] font-bold p-2 bg-orange-500 hover:bg-orange-600 transition-all ease-out duration-300 text-white border rounded-lg my-16  border-orange-500 "
          onClick={handleUpdateBlog}
        >
          Update Blog
        </button>
      <div className='font-semibold my-10 text-center'>Want to go to Main Page of <Link to='/admindashboard' className='text-purple-500 underline'> Admin Dashboard</Link></div>

      </div>
     
    </>
  );
};
const dropzoneStyles = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};
export default AdminBlogUpdate;
