import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";

const GetServiceData = () => {
  const [data, setData] = useState([]);

  // Fetch data from the server
  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        "https://api.mbasolutions.co/api/categories",
        config
      ); // Adjust the URL accordingly
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error Getting data",
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const confirmed = await Swal.fire({
        title: "Are you sure?",
        text: "This action cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (confirmed.isConfirmed) {
        await axios.delete(
          `https://api.mbasolutions.co/api/categories/${id}`,
          config
        );

        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Data has been deleted successfully",
        });

        fetchData();
      }
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  return (
    <>
      <div className="min-h-[75vh]">
        <h1 className="text-[32px] font-bold drop-shadow-lg text-center mt-36 mb-16">
          Data List
        </h1>
        <table className="mx-auto ">
          <thead className="pb-24 ">
            <tr>
              <th className="text-[21px] pr-48">Name</th>
              <th className="text-[21px] pr-48">Image</th>
              <th className="text-[21px] pr-48">Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item._id}>
                <td className="py-10">{item.name}</td>
                <td>
                  <img
                    src={`https://api.mbasolutions.co${item.image}`}
                    alt={item.name}
                    width="100"
                    height={100}
                  />
                </td>
                <td>
                  <button
                    onClick={() => handleDelete(item._id)}
                    className="bg-red-500 hover:bg-red-700 text-[20px] text-white p-2"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
           
          </tbody>
        </table>
       
      </div>
    </>
  );
};

export default GetServiceData;
