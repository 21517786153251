import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Swal from "sweetalert2";
import axios from "axios";

const GetTracking = () => {
  const [data, setData] = useState([]);

  // Fetch data from the server
  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        "https://api.mbasolutions.co/api/tracking",
        config
      ); // Adjust the URL accordingly
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error Getting data",
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const confirmed = await Swal.fire({
        title: "Are you sure?",
        text: "This action cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (confirmed.isConfirmed) {
        await axios.delete(
          `https://api.mbasolutions.co/api/tracking/${id}`,
          config
        );

        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Data has been deleted successfully",
        });

        fetchData();
      }
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  return (
    <div>
      <div className="my-24 mx-6 md:mx-10">
        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr>
              <th className="border border-gray-300 px-4 py-2">
                Serial Number
              </th>
              <th className="border border-gray-300 px-4 py-2">Name</th>
              <th className="border border-gray-300 px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((person) => (
              <tr key={person._id} className="text-center">
                <td className="border border-gray-300 px-4 py-2">
                  {person.serialnumber}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {person.name}
                </td>

                <td className="border border-gray-300 px-4 py-2">
                  <a
                    href={`https://api.mbasolutions.co/${person.image}`}
                    download={person.image}
                    className="text-blue-500 hover:underline"
                  >
                    Download File
                  </a>
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded-md"
                    onClick={() => handleDelete(person._id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
       
        
      </div>
    </div>
  );
};

export default GetTracking;
