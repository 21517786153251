import iso2700122301 from '../../assets/Toolkit/ISO-27001-ISO-22301-Premium-Documentation-Toolkit.jpg' 
import iso13485M from '../../assets/Toolkit/ISO-13485-MDR-Integrated-Documentation-Toolkit.jpg'
import is9001 from '../../assets/Toolkit/ISO-9001.jpg'
import iso14001 from '../../assets/Toolkit/ISO-14001-Documentation-Toolkit.jpg'
import iso22301 from '../../assets/Toolkit/ISO-22301-Documentation-Toolkit.jpg'
import iso270012022 from '../../assets/Toolkit/ISO-27001-2022.jpg'
import iso27001 from '../../assets/Toolkit/ISO-27001.jpg' 
import iso45010 from '../../assets/Toolkit/ISO-45001.jpg'
import isoGDPR from '../../assets/Toolkit/ISO-13485-MDR.jpg'
import iso20000 from '../../assets/Toolkit/ISO-20000-Documentation-Toolkit.jpg'
import isogdpr27001 from '../../assets/Toolkit/ISO-13485-MDR-Integrated-Documentation-Toolkit.jpg'
import iso270012701727018 from '../../assets/Toolkit/ISO-27001-ISO-27017-ISO-27018.jpg'
import iso17025 from '../../assets/Toolkit/ISO-17025-Documentation-Toolkit.jpg'
import iso13485 from '../../assets/Toolkit/ISO-13485-Documentation-Toolkit.jpg'
import iso9001P from '../../assets/Toolkit/ISO-9001-Premium.jpg'
import iso90011400145001 from '../../assets/Toolkit/ISO-9001-ISO-14001-ISO-45001-Integrated-Documentation-Toolkit.jpg'
import isoeugdpr from '../../assets/Toolkit/EU-GDPR.jpg'
import iso14001p from '../../assets/Toolkit/ISO-14001-Premium-Documentation-Toolkit.jpg'
import iso900114001 from '../../assets/Toolkit/ISO-9001-ISO-14001-Integrated-Documentation-Toolkit.jpg'
import iso45001p from '../../assets/Toolkit/ISO-45001-Premium-Documentation-Toolkit.jpg'
import iatf16949 from '../../assets/Toolkit/IATF-16949.jpg'
import as9100d from '../../assets/Toolkit/AS9100D.jpg'
import iso22301B from '../../assets/Toolkit/ISO-22301-Business-Impact-Analysis-Toolkit.jpg'
import iso2700122301i from '../../assets/Toolkit/ISO-27001-ISO-22301-Internal-Audit-Toolkit.jpg'
import iso270012301r from '../../assets/Toolkit/ISO-27001-ISO-22301-Risk-Assesment-Toolkit.jpg'
import iso9001 from '../../assets/Toolkit/ISO-9001-Internal-Audit-Toolkit.jpg'
import iso90012015 from '../../assets/Toolkit/ISO-9001-Risk-Management-Toolkit.jpg'
import iso140012015 from '../../assets/Toolkit/ISO-14001-Internal-Audit-Toolkit.jpg'
import as9100 from '../../assets/Toolkit/AS9100-P.jpg'
import iso13485i from '../../assets/Toolkit/ISO-13485-Internal-Audit-Toolkit.jpg'
import itsm from '../../assets/Toolkit/ITSM-Change-Management-Toolkit.jpg'
import itsmB from '../../assets/Toolkit/ITSM-Business-Relationship-Management-Toolkit.jpg'
import iatf16949i from '../../assets/Toolkit/IATF-16949-Internal-Audit.jpg'
import iso45001i from '../../assets/Toolkit/ISO-45001-Internal-Audit-Toolkit.jpg'
import gdpr from '../../assets/Toolkit/GDPR-Data-Mapping-DPIA.jpg'
import gdprm from '../../assets/Toolkit/GDPR-Mini-Toolkit-For-Websites.jpg'
import gdprc from '../../assets/Toolkit/GDPR-Consent-Data-Subject-Rights.jpg' 
import iso2700122301c from '../../assets/Toolkit/ISO-27001-ISO-22301-Consultant-Toolkit.jpg'
import iso9001c from '../../assets/Toolkit/ISO-9001-Consultant-Toolkit.jpg'
import eugdpr from '../../assets/Toolkit/EU-GDPR-Consultant.jpg'
import iso13458eumdr from '../../assets/Toolkit/ISO-13485-EU-MDR-Consultant-Toolkit.jpg'
import iso20000c from '../../assets/Toolkit/ISO-20000-Consultant-Toolkit.jpg'
import iso14001c from '../../assets/Toolkit/ISO-14001-Consultant-Toolkit.jpg'
import iso45001c from '../../assets/Toolkit/ISO-45001-Consultant-Toolkit.jpg'
import iatf1649c from '../../assets/Toolkit/IATF-16949-Consultant.jpg'
import iso9001t from '../../assets/Toolkit/ISO-9001-Transition-Toolkit.jpg'
import iso45001t from '../../assets/Toolkit/ISO-45001-Transition-Toolkit.jpg'
import iso140012015t from '../../assets/Toolkit/ISO-14001-Transition-Toolkit.jpg'
import as9100dt from '../../assets/Toolkit/AS9100-Rev-D-Transition-Toolkit.jpg'
import as9100r from '../../assets/Toolkit/AS9100-Rev-D-Transition-Toolkit.jpg'

export const ToolkitData = {
  toolkitBasicData: [
    {
      name: ['ISO 27001', 'ISO 22301'],
      heading: 'ISO 27001 & ISO 22301 Premium Documentation Toolkit',
      img: iso2700122301,
      type: ['Multiple Standards', 'Regular Toolkits'],
      price: 199, // Add the price in dollars
    },
    {
      name: ['ISO 13485'],
      heading: 'ISO 13485 & MDR Integrated Documentation Toolkit',
      img: iso13485M,
      type: ['Multiple Standards'],
      price: 139, // Add the price in dollars
    },
    {
      name: ['ISO 9001'],
      heading: 'ISO 9001 Documentation Toolkit',
      img: is9001,
      type: ['Regular Toolkits', '9001'],
      price: 129, // Add the price in dollars
    },
    {
      name: ['ISO 14001'],
      heading: 'ISO 14001 Documentation Toolkit',
      img: iso14001,
      type: ['Regular Toolkits', 'ISO 14001'],
      price: 169, // Add the price in dollars
    },
    {
      name: ['ISO 22301'],
      heading: 'ISO 22301 Documentation Toolkit',
      img: iso22301,
      type: ['ISO 22301', 'Regular Toolkits'],
      price: 127, // Add the price in dollars
    },
    {
      name: ['ISO 27001', 'ISO 2022'],
      heading: 'ISO 27001 2022 Transition Toolkit',
      img: iso270012022,
      type: ['Regular Toolkits', 'ISO 27001'],
      price: 149, // Add the price in dollars
    },
    {
      name: ['ISO 27001'],
      heading: 'ISO 27001 Documentation Toolkit',
      img: iso27001,
      type: ['Regular Toolkits', 'ISO 27001'],
      price: 129, // Add the price in dollars
    },
    {
      name: ['ISO 45001'],
      heading: 'ISO 45001 Documentation Toolkit',
      img: iso45010,
      type: ['ISO 45001', 'Regular Toolkits'],
      price: 898, // Add the price in dollars
    },
    {
      name: ['EU GDPR'],
      heading: 'EU GDPR Documentation Toolkit',
      img: isoGDPR,
      type: ['EU GDPR', 'Regular Toolkits'],
      price: 899, // Add the price in dollars
    },
    {
      name: ['ISO 20000'],
      heading: 'ISO 20000 Documentation Toolkit',
      img: iso20000,
      type: ['ISO 20000', 'Regular Toolkits'],
      price: 439, // Add the price in dollars
    },
    {
      name: ['EU GDPR', 'ISO 27001'],
      heading: 'EU GDPR & ISO 27001 Integrated Documentation Toolkit',
      img: isogdpr27001,
      type: ['ISO GDPR', 'Multiple Standards'],
      price: 199, // Add the price in dollars
    },
    {
      name: ['ISO 27001', 'ISO 27017', 'ISO 27018'],
      heading: 'ISO 27001 & ISO 27017 & ISO 27018 Cloud Toolkit',
      img: iso270012701727018,
      type: ['Regular Toolkits', 'ISO 27001'],
      price: 179, // Add the price in dollars
    },
    {
      name: ['ISO 17025'],
      heading: 'ISO 17025 Documentation Toolkit',
      img: iso17025,
      type: ['Regular Toolkits', 'ISO 17025'],
      price: 149, // Add the price in dollars
    },
    {
      name: ['ISO 13485'],
      heading: 'ISO 13485 Documentation Toolkit',
      img: iso13485,
      type: ['Regular Toolkits', 'ISO 13485'],
      price: 129, // Add the price in dollars
    },
    {
      name: ['ISO 9001'],
      heading: 'ISO 9001 Premium Documentation Toolkit',
      img: iso9001P,
      type: ['Regular Toolkits', 'ISO 9001'],
      price: 199, // Add the price in dollars
    },
    {
      name: [],
      heading: 'ISO 9001, ISO 14001 AND ISO 45001 INTEGRATED DOCUMENTATION TOOLKIT',
      img: iso90011400145001,
      type: ['Multiple Standards'],
      price: 249, // Add the price in dollars
      description: '',
    },
    {
      name: ['EU GDPR'],
      heading: 'EU GDPR Premium Documentation Toolkit',
      img: isoeugdpr,
      type: ['Regular Toolkits', 'EU GDPR'],
      price: 199, // Add the price in dollars
      description: '',
    },
    {
      name: ['ISO 14001'],
      heading: 'ISO 14001 PREMIUM DOCUMENTATION TOOLKIT',
      img: iso14001p,
      type: ['Regular Toolkits', 'ISO 14001'],
      price: 199, // Add the price in dollars
      description: '',
    },
    {
      name: ['ISO 9001', 'ISO 14001'],
      heading: 'ISO 9001 & ISO 14001 INTEGRATED DOCUMENTATION TOOLKIT',
      img: iso900114001,
      type: ['Regular Toolkits', 'ISO 9001'],
      price: 229, // Add the price in dollars
      description: '',
    },
    {
      name: ['ISO 45001'],
      heading: 'ISO 45001 PREMIUM DOCUMENTATION TOOLKIT',
      img: iso45001p,
      type: ['Regular Toolkits', 'ISO 45001'],
      price: 199, // Add the price in dollars
      description: '',
    },
    {
      name: ['IATF 16949', 'IATF 2016'],
      heading: 'IATF 16949:2016 DOCUMENTATION TOOLKIT',
      img: iatf16949,
      type: ['Regular Toolkits', 'IATF 16949'],
      price: 249, // Add the price in dollars
      description: '',
    },
    {
      name: ['AS9100D'],
      heading: 'AS9100D DOCUMENTATION TOOLKIT',
      img: as9100d,
      type: ['Regular Toolkits', 'AS9100D'],
      price: 199, // Add the price in dollars
      description: '',
    },
    {
      name: ['ISO 22301'],
      heading: 'ISO 22301 BUSINESS IMPACT ANALYSIS TOOLKIT',
      img: iso22301B,
      type: ['Regular Toolkits', 'ISO 22301'],
      price: 149, // Add the price in dollars
      description: '',
    },
    {
      name: ['ISO 22301'],
      heading: 'ISO 22301 BUSINESS IMPACT ANALYSIS TOOLKIT',
      img: iso2700122301i,
      type: ['Regular Toolkits', 'ISO 22301'],
      price: 149, // Add the price in dollars
    },
    {
      name: ['ISO 27001', 'ISO 22301'],
      heading: 'ISO 27001/ISO 22301 RISK ASSESSMENT TOOLKIT',
      img: iso270012301r,
      type: ['Regular Toolkits', 'Multiple Standards'],
      price: 179, // Add the price in dollars
      description: '',
    },
    {
      name: ['ISO 9001'],
      heading: 'ISO 9001 INTERNAL AUDIT TOOLKIT',
      img: iso9001,
      type: ['Regular Toolkits', 'ISO 9001'],
      price: 149, // Add the price in dollars
      description: '',
    },
    {
      name: ['ISO 9001'],
      heading: 'ISO 9001:2015 RISK MANAGEMENT TOOLKIT',
      img: iso90012015,
      type: ['Regular Toolkits', 'ISO 9001'],
      price: 149, // Add the price in dollars
    },
    {
      name: ['ISO 14001'],
      heading: 'ISO 14001:2015 INTERNAL AUDIT TOOLKIT',
      img: iso140012015,
      type: ['Regular Toolkits', 'ISO 14001'],
      price: 149, // Add the price in dollars
    },
    {
      name: ["AS9100"],
      heading: 'AS9100D PREMIUM DOCUMENTATION TOOLKIT',
      img: as9100,
      type: ['Regular Toolkits', 'AS9100'],
      price: 199, // Add the price in dollars
    },
    {
      name: ['ISO 13485'],
      heading: 'ISO 13485 Internal Audit Toolkit',
      img: iso13485i,
      type: ['Regular Toolkits', 'ISO 13485'],
      price: 149, // Add the price in dollars
    },
    {
      name: ['ISO 20000'],
      heading: 'ITSM Change Management Toolkit',
      img: itsm,
      type: ['Regular Toolkits', 'ISO 20000'],
      price: 129, // Add the price in dollars
    },
    {
      name: ['ISO 20000'],
      heading: 'ITSM Business Relationship Management Toolkit',
      img: itsmB,
      type: ['Regular Toolkits', 'ISO 20000'],
      price: 129, // Add the price in dollars
    },
    {
      name: ['IATF 16949'],
      heading: 'IATF 16949:2016 Internal Audit Toolkit',
      img: iatf16949i,
      type: ['Regular Toolkits', 'IATF 16949'],
      price: 149, // Add the price in dollars
    },
    {
      name: ['ISO 45001'],
      heading: 'ISO 45001 Internal Audit Toolkit',
      img: iso45001i,
      type: ['Regular Toolkits', 'ISO 45001'],
      price: 149, // Add the price in dollars
    },
    {
      name: ['EU GDPR'],
      heading: 'EU GDPR Data Mapping & DPIA Toolkit',
      img: gdpr,
      type: ['Regular Toolkits', 'EU GDPR'],
      price: 129, // Add the price in dollars
    },
    {
      name: ['EU GDPR'],
      heading: 'GDPR Mini Toolkit for Websites',
      img: gdprm,
      type: ['Regular Toolkits', 'EU GDPR'],
      price: 99, // Add the price in dollars
    },
    {
      name: ['EU GDPR'],
      heading: 'GDPR Consent & Data Subject Rights Toolkit',
      img: gdprc,
      type: ['Regular Toolkits', 'EU GDPR'],
      price: 129, // Add the price in dollars
    },
    {
      name: ['ISO 27001', 'ISO 22301'],
      heading: 'ISO 27001 & ISO 22301 Consultant Toolkit',
      img: iso2700122301c,
      type: ['Toolkits for Consultants', 'Multiple Standards'],
      price: 299, // Add the price in dollars
    },
    {
      name: ['ISO 9001'],
      heading: 'ISO 9001 Consultant Toolkit',
      img: iso9001c,
      type: ['Toolkits for Consultants', 'ISO 9001'],
      price: 249, // Add the price in dollars
    },
    {
      name: ['EU GDPR'],
      heading: 'EU GDPR Consultant Toolkit',
      img: eugdpr,
      type: ['Toolkits for Consultants', 'EU GDPR'],
      price: 299, // Add the price in dollars
    },
    {
      name: ['ISO 13485'],
      heading: 'ISO 13485 & EU MDR Consultant Toolkit',
      img: iso13458eumdr,
      type: ['Multiple Standards', 'Toolkits for Consultants'],
      price: 249, // Add the price in dollars
    },
    {
      name: ['ISO 20000'],
      heading: 'ISO 20000 Consultant Toolkit',
      img: iso20000c,
      type: ['Toolkits for Consultants', 'ISO 20000'],
      price: 249, // Add the price in dollars
    },
    {
      name: ['ISO 14001'],
      heading: 'ISO 14001 Consultant Toolkit',
      img: iso14001c,
      type: ['Toolkits for Consultants', 'ISO 14001'],
      price: 249, // Add the price in dollars
    },
    {
      name: ['ISO 45001'],
      heading: 'ISO 45001 Consultant Toolkit',
      img: iso45001c,
      type: ['Toolkits for Consultants', 'ISO 45001'],
      price: 249, // Add the price in dollars
    },
    {
      name: ['IATF 16949'],
      heading: 'IATF 16949 Consultant Toolkit',
      img: iatf1649c,
      type: ['Toolkits for Consultants', 'IATF 16949'],
      price: 249, // Add the price in dollars
    },
    {
      name: ['ISO 9001'],
      heading: 'SO 9001 Transition Toolkit',
      img: iso9001t,
      type: ['Regular Toolkits', 'ISO 9001'],
      price: 149, // Add the price in dollars
    },
    {
      name: ['ISO 45001'],
      heading: 'ISO 45001 Transition Toolkit',
      img: iso45001t,
      type: ['Regular Toolkits', 'ISO 45001'],
      price: 149, // Add the price in dollars
    },
    {
      name: ['ISO 14001', 'ISO 2015'],
      heading: 'ISO 14001:2015 Transition Toolkit',
      img: iso140012015t,
      type: ['Regular Toolkits', 'ISO 14001'],
      price: 149, // Add the price in dollars
    },
    {
      name: ['AS9100'],
      heading: 'AS9100D Internal Audit Toolkit',
      img: as9100dt,
      type: ['Regular Toolkits', 'AS9100'],
      price: 149, // Add the price in dollars
    },
    {
      name: ['AS9100'],
      heading: 'AS9100 Rev D Transition Toolkit',
      img: as9100r,
      type: ['Regular Toolkits', 'AS9100'],
      price: 149, // Add the price in dollars
    },
  ],
};
