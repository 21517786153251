import React from 'react'
import TeamSection1 from './TeamSection1'
import TeamSection2 from './TeamSection2'
import Navbar from '../../components/Navbar'
import Layout from '../../components/Layout'
const Team = () => {
  return (
    <Layout title={"MBA Solutions - Team "} keywords={"Team, Our Team, Meet the Team, MBA Team, Professional Team, Team Members,Mba Solutions,Muhammad Atif"}>

      <TeamSection1 />
      <div className=' bg-[#F2F2F2]'>
      <TeamSection2 />
      </div>
    </Layout>
  )
}

export default Team