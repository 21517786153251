// TeamSection.js
import React, { useEffect, useState } from "react";
import { data } from '../../data';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";
import Swal from "sweetalert2";
import Team1 from '../../assets/Team/Team1.jpeg'

const Team = () => {
    const {TeamSlider}= data;
    const [dataa, setDataa] = useState([]);

    // Fetch data from the server
    const fetchData = async () => {
        try {
            const response = await axios.get("https://api.mbasolutions.co/api/team/");
            setDataa(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
           
        }
    };
    
    useEffect(() => {
        fetchData();
    }, []);

    const responsive = {
       
        desktop: {
          breakpoint: { max: 4000, min: 0 },
          items: 1,
        },
        
      };
    
  return (
    <section className="bg-gray-100  w-full  px-4 md:px-10   py-20">
      <div className="  text-center  ">
        <h2 className=" text-4xl font-semibold  mb-6  pb-3">
          Our Amazing Team
        </h2>
        <p className="text-center text-xl  text-[#3a353a66] pb-24  ">The Talented People Behind the Scenes of the Organization</p>
     
     <div className=" w-[100%] mb-28">
     <Carousel
          responsive={responsive}
          autoPlay={true}
          infinite={true}
          arrows={false}
          
        >
{
    TeamSlider.map((li,i)=>(
        <img src={li.img} alt={li.name}  className="w-[100%] h-[250px] md:h-[550px] lg:h-[600px] mx-0"/>
    ))


}





            </Carousel>

     </div>
     
        <div className="mb-12 px-4 md:px-12 ">
          <div className="flex justify-center items-center cursor-pointer">
            <div className="bg-white px-6 md:px-20 lg:px-32 py-12 shadow-lg  rounded-lg hover:shadow-2xl transition duration-300 ease-in-out">
              <img
                src={Team1}
                alt=''
                className="mx-auto w-[180px] h-[180px] md:w-[250px] md:h-[250px] rounded-full mb-4"
              />
              <h3 className="text-2xl font-semibold text-gray-800">
                Muhammad Atif
              </h3>
              <p className="text-gray-600">CEO</p>
              <p className="text-gray-700 mt-2">Our visionary CEO leading us to new horizons with a wealth of experience, innovative thinking, and a passion for driving our organization's success.</p>
            </div>
          </div>
        </div>

        <div className="grid w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-4 md:px-12">
                {dataa && dataa.length > 0 ? (
                    dataa.map((item, index) => (
                        <div
                            key={index}
                            className="bg-white p-6 md:p-8 cursor-pointer shadow-lg rounded-lg hover:shadow-2xl transition duration-300 ease-in-out"
                        >
                            <img
                                src={`https://api.mbasolutions.co${item.image}`}
                                alt={item.name}
                                className="mx-auto w-48 h-48 rounded-full mb-4"
                            />
                            <h3 className="text-2xl font-semibold text-gray-800">
                                {item.name}
                            </h3>
                            <p className="text-gray-600">{item.position}</p>
                            <p className="text-gray-700 py-7">{item.paragraph}</p>
                        </div>
                    ))
                ) : (
                    <p>Loading...</p> 
                )}
            </div>
      </div>
    </section>
  );
};
export default Team;
