import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import Layout from '../../components/Layout';
import BlogsSection1 from './BlogsSection1';

const AdminBlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchBlogs = async () => {
    try {
      const response = await axios.get('https://api.mbasolutions.co/api/blogs/get');
      setBlogs(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error Getting data',
      });
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const truncateWords = (text, maxWords) => {
    const words = text.split(' ');
    const truncatedWords = words.slice(0, maxWords);
    const truncatedText = truncatedWords.join(' ');
    return words.length > maxWords ? `${truncatedText}.....` : truncatedText;
  };

  const handleblogid = (_id) => {
    localStorage.setItem('specificBlog', _id);
   
  };

  const filteredBlogs = blogs.filter(
    (blog) => blog.name && blog.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const reversedBlogs = [...filteredBlogs].reverse()
  return (
    <>
      <Layout title={"MBA Solutions - Blogs "} keywords={"Blog,  MBA Solutions , MBA Solutions Blogs "}>
       <BlogsSection1 />
        <div className=" mx-auto pt-6 bg-[#F2F2F2] ">
          <div className=' flex justify-end items-end mt-12 '>
            <input
              type="text"
              placeholder="Search blogs...."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className=" outline-1 focus:shadow-xl shadow-md w-[50%] outline-orange-500 md:w-[35%] lg:w-[27%] py-2 mx-6 px-4 mb-4 border border-gray-300 rounded-lg"
            />
          </div>
          <div className="mx-6 grid grid-cols-1 md:grid-cols-2 gap-6 lg:grid-cols-3 py-16">
            {reversedBlogs.map((blog) => (
              <Link to={`/blog/${blog._id}`} key={blog._id}>
                <div className="cursor-pointer transition-all h-[590px] duration-300  ease-out bg-white py-8 rounded-xl shadow-lg flex justify-center flex-col items-center   hover:shadow-2xl" onClick={() => handleblogid(blog._id)}>
                  <h3 className="text-xl font-semibold mb-4 py-3">{blog.name}</h3>
                  <img
                    src={`https://api.mbasolutions.co${blog.image}`}
                    alt="Blog Image"
                    className="mb-3 max-w-[90%] h-[350px] rounded-lg"
                  />
                  <div
                    dangerouslySetInnerHTML={{ __html: truncateWords(blog.paragraph, 15) }}
                    className="text-gray-700 pt-4 py-3 mx-8"
                  />
                </div>
              </Link>
            ))}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AdminBlogList;
