import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { FormAuthenticationmessage } from "./FormAuthenticationmessage";
import axios from "axios";
import Swal from "sweetalert2";

const ContactSection4 = () => {
  const validate = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is required"),
    subject: Yup.string(),
    message: Yup.string(),
    requestCall: Yup.string(),
  });

  return (
    <div>
      <Formik
        initialValues={{
          name: "",
          email: "",
          subject: "",
          message: "",
          requestCall: false,
        }}
        validationSchema={validate}
        onSubmit={async (values, { resetForm }) => {
          try {
            Swal.fire({
              title: 'Sending Data',
              text: 'Please wait...',
              icon: 'info',
              allowOutsideClick: false,
              showConfirmButton: false,
              showCancelButton: false,
              didOpen: () => {
                Swal.showLoading();
              },
            });
            await validate.validate(values, { abortEarly: false }); // Run validation for all fields

            // If the form is valid, proceed with submitting the data
            resetForm();
            const response = await axios.post(
              "https://api.mbasolutions.co/shortForm",
              values
            );
           

            // Show success alert
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "Data has been sent successfully",
            });
          } catch (error) {
            console.error(error);

            // If the form is not valid, show an alert error
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Network Error.Please Try Again Later.",
            });
          }
        }}
      >
        {(formik) => (
          <div>
          <div className="md:w-[50%] w-[90%]  mx-auto shadow-2xl py-24  mb-28 rounded-3xl bg-white">
         <h1 className="mt-10 mx-auto max-w-md p-6 rounded-lg  text-gray-800 text-center shadow-lg shadow-black/40 bg-gray-100/50  font-bold text-xl md:text-2xl lg:text-3xl">
  Have Any Questions?
</h1>

            <Form className="mx-7 md:mx-11 text-[#4c4d56] mt-16">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 md:gap-x-8 w-[100%] ">
                <div className="mb-5">
                  <FormAuthenticationmessage
                    type="text"
                    id="name"
                    name="name"
                    className="form-control py-3 border text-[#415674] text-[13px] w-[90%] md:w-[100%]  border-slate-300 my-3 rounded-md outline-blue-400 px-3 "
                    placeholder="Name"
                  />
                </div>
                <div className="form-group">
                  <FormAuthenticationmessage
                    type="text"
                    id="email"
                    name="email"
                    className="form-control py-3 border text-[#415674] text-[13px] w-[90%] md:w-[100%]  border-slate-300 my-3 rounded-md outline-blue-400 px-3 "
                    placeholder="Email"
                  />
                </div>
              </div>
              <div>
                <FormAuthenticationmessage
                  type="text"
                  id="subject"
                  name="subject"
                  className="form-control py-3 border text-[#415674] text-[13px] w-[90%] md:w-[100%]  border-slate-300 mb-5 rounded-md outline-blue-400 px-3 "
                  placeholder="Subject"
                />
               
                <FormAuthenticationmessage
                  textarea
                  id="message"
                  name="message"
                  className="form-control py-3 flex justify-start items-start p-0 min-h-[150px] border text-[#415674] text-[13px] w-[90%] md:w-[100%]  border-slate-300 my-3 rounded-md outline-blue-400 px-3"
                  placeholder="Write your Question"
                />
                <div className="flex">
                   <FormAuthenticationmessage
                  type="checkbox"
                  name="requestCall"
                  className="mr-2  mt-1 w-4  h-4 mt-"
                
                  checked={formik.values.requestCall} // Add this line to control the checkbox state
                />
                    <label >Reuest for Call</label>

                </div>
                <button
                  className="btn md:w-[140px]  md-w-[7%] hover:shadow-xl bg-orange-500 hover:orange-600 transition-all duration-200 ease-out text-white cursor-pointer mt-3 px-5 py-3 md:px-8 md:py-4 rounded-md text-xl "
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </Form>
          </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default ContactSection4;
