import React from "react";
import logo from '../assets/Logo.png'
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { BsFacebook,  BsLinkedin } from "react-icons/bs";
import fiverr from '../assets/fiverr.png'

const Footer = () => {
  const handleLinkClick = () => {
    scroll.scrollToTop();
  };
  return (
    <footer className=" poppins">
      <div className="pt-16 md:pt-28   relative grid grid-cols-1 gap-y-6 lg:grid-cols-4 md:grid-cols-2  mx-auto bg-white md:px-6 p-5 lg:gap-x-20">
      <div className="mt-[-20px]">
      <Link to="/" onClick={handleLinkClick}>

            <img className="h-[64.5] w-[130.7px] md:w-auto md:h-24 md:ml-0 ml-[-14px]" src={logo} alt="MbaSolutions" />
          </Link>      
            <p className="text-[#9B9B97] md:ml-6 mt-3">
            Unlocking Potential, Achieve Excellence through Our ISO-Certified Standards and Empower Your Success Journey
        </p>
        <div></div>
      </div>
      <div className="mb-6 ">
      
        <h2 className="font-bold text-[18px] uppercase drop-shadow-lg text-black">Information</h2>
        <div className="pt-4 w-20 border-b-4 border-[#F86624]"></div>
        <ul className="pt-5 text-[#9B9B97] ">
        <Link to='/contact'>  <li className="pt-2 cursor-pointer hover:pl-2 transition-all duration-300 ease-out hover:text-[#F86624]" onClick={handleLinkClick} >Contact Us</li></Link>
        <Link to='/about'>  <li className="pt-2 cursor-pointer hover:pl-2 transition-all duration-300 ease-out hover:text-[#F86624]" onClick={handleLinkClick}>About Us</li></Link>
        <Link to='/team'>  <li className="pt-2 cursor-pointer hover:pl-2 transition-all duration-300 ease-out hover:text-[#F86624]" onClick={handleLinkClick}>Our Team</li></Link>
        <Link to='/terms'>  <li className="pt-2 cursor-pointer hover:pl-2 transition-all duration-300 ease-out hover:text-[#F86624]" onClick={handleLinkClick}>Terms & Conditions</li></Link>
        </ul>
      </div>
      <div className="mb-6">
      <h2 className="font-bold text-[18px] uppercase drop-shadow-lg text-black">Pages</h2>
        <div className="pt-4 w-20 border-b-4 border-[#F86624]"></div>
        <ul className="pt-5 text-[#9B9B97] ">
          <Link to='/services'><li className="pt-2 cursor-pointer hover:pl-2 transition-all duration-300 ease-out hover:text-[#F86624]" onClick={handleLinkClick}>Services</li></Link>
         <Link to='/tracking'> <li className="pt-2 cursor-pointer hover:pl-2 transition-all duration-300 ease-out hover:text-[#F86624]" onClick={handleLinkClick}>Tracking</li></Link>
         <Link to='/blog'> <li className="pt-2 cursor-pointer hover:pl-2 transition-all duration-300 ease-out hover:text-[#F86624]" onClick={handleLinkClick}>Blogs</li></Link>
         <Link to='/toolkits'> <li className="pt-2 cursor-pointer hover:pl-2 transition-all duration-300 ease-out hover:text-[#F86624]" onClick={handleLinkClick}>Toolkits</li></Link>
        </ul>
      </div>
      <div>
      <h2 className="font-bold text-[18px] uppercase drop-shadow-lg text-black">Follow us</h2>
        <div className="pt-4 w-20 border-b-4 border-[#F86624]"></div>
        <div className="flex pt-5 items-center  md:justify-normal space-x-6">
          <a href="https://www.facebook.com/MBA.Solutions.Official" target="_blank"> <div className="p-2 transition-all duration-300 ease-in cursor-pointer rounded-full  bg-[#F86624] hover:bg-white text-white hover:text-[#F86624]" onClick={handleLinkClick}> <BsFacebook size={18} /> </div></a>
         <a href="https://www.linkedin.com/company/64721224/admin/feed/posts/" target="_blank">  <div className="p-2 transition-all duration-300 ease-in cursor-pointer rounded-full  bg-[#F86624] hover:bg-white text-white hover:text-[#F86624]" onClick={handleLinkClick}> <BsLinkedin size={18}/></div></a>
       <a href="https://www.fiverr.com/users/mba_solutions1/seller_dashboard" target="_blank">    <div className="px-[0.8rem] py-1 transition-all duration-300 ease-in cursor-pointer rounded-full  bg-[#F86624] hover:bg-white text-white hover:text-[#F86624] text-[18px] font-bold" onClick={handleLinkClick}> fi</div></a>
          </div>
      </div>
     
      
      </div>
      
      
      <div className="text-[16px] text-center  text-gray-500 bg-white md:px-7 p-4">
          © {new Date().getFullYear()} <span className="text-black">All Rights Reserved by MBA Solutions Pvt.Ltd. </span>
        </div>
    </footer>
  );
};

export default Footer;
