import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from "react-scroll";
const Section1 = () => {
  const [skillBuilding, setSkillBuilding] = useState(null);
  const handleLinkClick = () => {
    scroll.scrollToTop();
  };
  useEffect(() => {
    // Make the Axios GET request to fetch data
    Axios.get('https://api.mbasolutions.co/api/categories/get')
      .then((response) => {
        // Filter the data to find the object with name: "Auditing"
        const Skill_Building = response.data.find(item => item.name === "Skill Building");

        // Set the auditing data in the state
        setSkillBuilding(Skill_Building);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <div className='my-8 mt-16 md:mx-10 mx-4 '>
      {skillBuilding ? (
        <div>
          
          <h1 className='text-[32px] font-bold drop-shadow-lg text-center'> {skillBuilding.name}</h1>
          {/* Add more data properties here */}

  <div className={`flex justify-center item-center  ${skillBuilding.image?'my-20':'mb-20'
  }`} >
          <img src={`https://api.mbasolutions.co${skillBuilding.image}`} className="max-w-full rounded-md mx-auto max-h-[70vh]" alt='' />
          </div>
          <p className='my-12 text-center'>{skillBuilding.paragraph}</p>


        </div>
        
      ) : (
        <p>Loading...</p>
      )}
        
        <div className='mt-8'>
          <ul className='list-disc ml-5 py-2 text-[18px] leading-loose'>
          <Link to={{ pathname: '/pecb/ISO training' }}className='cursor-pointer hover:text-gray-700'  onClick={handleLinkClick}>  <li className='underline'>ISO training </li></Link>
          <Link to={{ pathname:'/pecb/Food Safety Systems training'}} className='cursor-pointer hover:text-gray-700' onClick={handleLinkClick}>   <li className='underline'>Food Safety Systems training</li></Link>
          <Link to={{ pathname:'/pecb/IOSH'}} className='cursor-pointer hover:text-gray-700' onClick={handleLinkClick}>   <li className='underline'>IOSH</li></Link>
          <Link to={{ pathname:'/pecb/HABC'}} className='cursor-pointer hover:text-gray-700' onClick={handleLinkClick}>   <li className='underline'>HABC</li></Link>
          <Link to={{ pathname:'/pecb/British Safety Council Courses'}} className='cursor-pointer hover:text-gray-700' onClick={handleLinkClick}>   <li className='underline'>British Safety Council Courses</li></Link>
          <Link to={{ pathname:'/pecb/RSPH'}} className='cursor-pointer hover:text-gray-700' onClick={handleLinkClick}>  <li className='underline'>RSPH</li></Link>
          <Link to={{ pathname:'/pecb/CIEH'}} className='cursor-pointer hover:text-gray-700' onClick={handleLinkClick}>   <li className='underline'>CIEH</li></Link>
          <Link to={{ pathname:'/pecb/OSHA'}} className='cursor-pointer hover:text-gray-700' onClick={handleLinkClick}>   <li className='underline'>OSHA</li></Link>
          <Link to={{ pathname:'/pecb/IRCA Approved Lead Auditor Courses'}} className='cursor-pointer hover:text-gray-700' onClick={handleLinkClick}>   <li className='underline'>IRCA Approved Lead Auditor Courses</li></Link>
          <Link to={{ pathname:'/pecb/PECB Lead Auditor Courses'}} className='cursor-pointer hover:text-gray-700' onClick={handleLinkClick}>   <li className='underline'>PECB Lead Auditor Courses</li></Link>
          <Link to={{ pathname:'/pecb/Safety Courses'}} className='cursor-pointer hover:text-gray-700' onClick={handleLinkClick}>  <li className='underline'>Safety Courses</li></Link>
          <Link to={{ pathname:'/pecb/Industrial Trainings '}} className='cursor-pointer hover:text-gray-700' onClick={handleLinkClick}>   <li className='underline'>Industrial Trainings</li></Link>
          </ul>
          <p className='mt-12'>These courses are tailored to provide your team with the specialized knowledge and certifications they need to excel in their respective fields.</p>
          <p className='mt-2'>At MBA Solutions Pvt. Ltd., we believe that the true strength of an organization lies in the skills and expertise of its workforce. We work closely with you to customize training programs that align with your industry's requirements, your team's strengths, and your organization's objectives.</p>
          <p className='mt-2'>
          Join us on a journey to empower your workforce with the skills and certifications they need to thrive. Explore our "Skill Building" services today and let's work together to enhance your team's capabilities, opening new doors for success and growth. Your success through skill building is our commitment.
          </p>
        </div>
     
    </div>
  );
}

export default Section1;
