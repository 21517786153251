import React from 'react';
import Layout from '../../components/Layout';

const TermsAndConditions = () => {
  return (
    <Layout title={"MBA Solutions - Terms And Conditiions "} keywords={"terms, conditions,termsandconditions,"}>
    <div className='w-full bg-[#F2F2F2]'>
    
    <div className='md:mx-8 mx-5 '>
      <h2 className=' pt-12 pb-4 text-[18px]'>Terms and Conditions for MBA Programs and ISO Certifications Website:</h2>

      <ol className='flex flex-col space-y-3 '>
        <li className='pt-3 '>
          <strong>1. Acceptance of Terms:</strong> By accessing or using our website, you agree to comply with and be bound by the terms and conditions outlined here in. If you do not agree with these terms, please refrain from using our services.
        </li>

        <li>
          <strong>2. Services Offered:</strong> We provide information and services related to MBA programs and ISO certifications. This includes details about our programs, application processes, and the procedures for obtaining ISO certifications.
        </li>

        <li>
          <strong>.3 Application Process:</strong> The application process for MBA programs and ISO certifications is outlined on our website. It is your responsibility to provide accurate and complete information during the application process. Misrepresentation may lead to the rejection of your application.
        </li>

        <li>
          <strong>4. Fees and Payments:</strong> Details regarding program fees and certification costs are available on our website. Payment instructions and deadlines will be provided during the application process. Payments are non-refundable unless otherwise specified.
        </li>

        <li>
          <strong>5. Program and Certification Changes:</strong> We reserve the right to modify, suspend, or discontinue any aspect of our MBA programs or ISO certifications at our discretion. Any changes will be communicated through our website.
        </li>

        <li>
          <strong>6. Intellectual Property:</strong> All content on our website, including text, graphics, logos, and images, is the intellectual property of our organization. Unauthorized use or reproduction is prohibited.
        </li>

        <li>
          <strong>7. Confidentiality:</strong> Any information provided by you during the application process will be treated with confidentiality, as outlined in our privacy policy. We may share information with relevant certification bodies for the purpose of processing certifications.
        </li>

        <li>
          <strong>8. Disclaimer of Warranties:</strong> We make no representations or warranties regarding the accuracy, completeness, or suitability of the information provided on our website. We are not liable for any errors or omissions.
        </li>

        <li>
          <strong>9. Limitation of Liability:</strong> Our organization, its directors, officers, employees, or agents are not liable for any direct, indirect, incidental, special, or consequential damages arising from the use of our services.
        </li>

       
      </ol>

      <p className='py-3'>
        <strong>Contact Information:</strong> For any questions or concerns regarding these terms and conditions, please contact us at <b className='underline'> atif@mbasolutions.co.</b>
      </p>

      <p>
        By using our website and services, you acknowledge that you have read, understood, and agreed to these terms and conditions.
      </p>
    </div>
    </div>
    </Layout>
  );
};

export default TermsAndConditions;
