import { Link } from 'react-router-dom';
import styles from './styles.module.css';
import { useState } from 'react';
import axios from 'axios';


const Login=()=>{
    const [data,setData]=useState({
      
        email:"",
        password:""
    })
    const[error,setError]=useState()
  
    
    const handleSubmit=async (e)=>{
        e.preventDefault();
      try{
        const url = "https://api.mbasolutions.co/api/auth";
        const { data: res } = await axios.post(url, data);
localStorage.setItem("token",res.data)
window.location="/admindashboard"
       
    }
    catch(error){
if(error.response && error.response.status>=400 &&
    error.response.status<=500)
{
    setError(error.response.data.message)
}   
}
    
    }
    const handleChange = ({ currentTarget: input }) => {
		setData({ ...data, [input.name]: input.value });
	};
    return (
        <div className={styles.login_container}>
            <div className={styles.login_form_container}>
        <div className={styles.left}>
        <form className={styles.form_container}>
   
   <h1>Login Here</h1>
          <input type="email" placeholder='Email' name='email' value={data.email} required className={styles.input} onChange={handleChange}/>
   <input type="password" placeholder='Password' name='password' value={data.password} required className={styles.input} onChange={handleChange}/>
{error && <div className={styles.error_msg}>{error}</div>}
<button type='submit' className={styles.green_btn} onClick={handleSubmit}>Login</button>

</form>
<div className='font-semibold'>Want to go to <Link to='/' className='text-purple-500'> Main Page</Link></div>
        </div>

            </div>


        </div>

    )
}
export default Login;