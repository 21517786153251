import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
const Add = () => {
  const [data, setData] = useState({
    name: 'ISO training',
    fee:"",
    startdate: "",
    enddate: "",
    startendtime: "",
    batchtype: "",
    trainingmode: "",
    batchstatus: "",
    enroll:false,
  });

  function ChangeHandler(e) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  }
  const SubmitHandler = async () => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const url = "https://api.mbasolutions.co/api/addcourse";

      // Send the 'data' object as the request body
      const res = await axios.post(url, data, config);

      // Assuming your API returns a message property in the response
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Data has been Added successfully",
      });

  
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        console.log(error.response.data.message);
      }
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error in Adding Data.Please Try again later.",
      });
    }
  };


  return (
    <>
      <div className="text-center text-lg justify-center items-center mt-20 overflow-y-auto">
       
       
        <form action="">
        <div  className='mx-auto'>
          <select name="name" id="name"  onChange={(e) => ChangeHandler(e)}  value={data.name} className='border-2 hover:border-purple-500 outline-none h-10 m-2 w-[240px] text-sm text-gray-600'>
            <option value="ISO training ">ISO training </option>
            <option value="Food Safety Systems training">Food Safety Systems training</option>
            <option value="IOSH">IOSH</option>
            <option value="HABC">HABC</option>
            <option value="British Safety Systems training">British Safety Systems training</option>
            <option value="RSPH">RSPH</option>
            <option value="CIEH">CIEH</option>
            <option value="OSHA">OSHA</option>
            <option value="IRCA Approved Lead Auditor Courses">IRCA Approved Lead Auditor Courses</option>
            <option value="PECB Lead Auditor Courses">PECB Lead Auditor Courses</option>
            <option value="PECB Lead Implementer Courses">PECB Lead Implementer Courses</option>
            <option value="Safety Courses">Safety Courses</option>
            <option value="Industrial Trainings">Industrial Trainings</option>



          </select>
        </div>
          <input
            type="text"
            onChange={(e) => ChangeHandler(e)}
            id="startdate"
            placeholder="Starting Date"
            required
            value={data.startdate}
            className="border-2 hover:border-purple-500 outline-none h-10 m-2  text-md text-center "
          />
          <br />
          <input
            type="text"
            onChange={(e) => ChangeHandler(e)}
            id="fee"
            placeholder="Fee"
            value={data.fee}
            className="border-2 hover:border-purple-500 outline-none h-10 m-2  text-md text-center "
          />
          <br />

          <input
            type="text"
            onChange={(e) => ChangeHandler(e)}
            id="enddate"
            placeholder="End Date"
            value={data.enddate}
            className="border-2 hover:border-purple-500 outline-none h-10 m-2  text-md text-center "
          />
          <br />

          <input
            type="text"
            onChange={(e) => ChangeHandler(e)}
            id="startendtime"
            placeholder="Start to End time"
            value={data.startendtime}
            className="border-2 hover:border-purple-500 outline-none h-10 m-2  text-md text-center "
          />
          <br />
          <input
            type="text"
            onChange={(e) => ChangeHandler(e)}
            id="batchtype"
            placeholder="Batch Type"
            required
            value={data.batchtype}
            className="border-2 hover:border-purple-500 outline-none h-10 m-2  text-md text-center "
          />
          <br />

          <input
            type="text"
            onChange={(e) => ChangeHandler(e)}
            id="trainingmode"
            placeholder="training Mode"
            value={data.trainingmode}
            className="border-2 hover:border-purple-500 outline-none h-10 m-2  text-md text-center "
          />
          <br />
          <input
            type="text"
            onChange={(e) => ChangeHandler(e)}
            id="batchstatus"
            placeholder="Batch Status"
            value={data.batchstatus}
            className="border-2 hover:border-purple-500 outline-none h-10 m-2  text-md text-center "
          />
          <br />
          <select
  name="enroll"
  value={data.enroll.toString()}
  onChange={(e) => {
    const newValue = e.target.value === "true";
    ChangeHandler({ target: { id: "enroll", value: newValue } });
  }}
  className="py-3 border block mx-auto hover:border-purple-500 text-[#415674] text-[13px] w-[90%] border-slate-300 my-3 rounded-md outline-blue-400 px-3 md:w-[18%]"
>
  <option value="false">Enrollment Closed</option>
  <option value="true">Enrollment Open</option>
</select>

         
            <button
              onClick={SubmitHandler}
              align="center"
              className=" btn  bg-purple-500 hover:bg-purple-600  cursor-pointer text-center items-center p-3 text-white text-lg  rounded-lg"
            >
              Submit
            </button>
  
        </form>
      </div>
    </>
  );
};

export default Add;
