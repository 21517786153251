import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import { animateScroll as scroll } from "react-scroll";
import ScrollTrigger from "react-scroll-trigger";
import aboutbg from '../../assets/about-bg.jpg';

const AboutSecion2 = () => {
  const [counterOn, setCounterOn] = useState(false);

  const textSpring = useSpring({
    opacity: counterOn ? 1 : 0,
    transform: counterOn ? "translateY(0)" : "translateY(50%)",
    from: { opacity: 0, },
  });


  return (
    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>

      <div>
        <div className="pt-28 mx-6 md:mx-12 mb-20 relative">
          <h3 className=" text-3xl font-bold text-center pb-20 drop-shadow-lg">
            Our Vision
          </h3>
          <div className="flex lg:flex-row flex-col-reverse justify-between">
            <div className="lg:w-[50%]">
              <animated.p
                className="mb-16 mx-6 text-center lg:text-left mt-12"
                style={textSpring}
              >
                The Primary purpose of MBA Solutions is to help the clients
                organization for resource development & implement the strategies
                properly for improvement. We identify competitive strengths,
                performance gaps, and growth potential. We help to develop
                execution and control plans to assure implementation across the
                country.
              </animated.p>
            </div>
            <div>
              <animated.img
                src={aboutbg}
                alt=""
                className="lg:w-[400px] w-full h-[250px] mr-2"
                style={textSpring}
              />
            </div>
          </div>
        </div>
      </div>
    </ScrollTrigger>
  );
};

export default AboutSecion2;
