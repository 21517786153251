import React, { useState } from 'react';
import { MdArrowBack, MdArrowForward, MdDashboard,  MdExitToApp, MdPersonAdd, MdPeople, MdCreate, MdMailOutline, MdTrackChanges, MdSchool, MdLibraryBooks, MdAddAPhoto, MdList, MdWork, MdAssignment } from 'react-icons/md';
import logo from '../../././../assets/Logo.png';
import Cookies from 'js-cookie';
import { animateScroll as scroll } from "react-scroll";
import axios from 'axios';
export const SideNavbar = ({ onToggleSidebar }) => {
  const [sidebarVisible, setSidebarVisible] = useState(true);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
      onToggleSidebar();
      scroll.scrollToTop();
    };
    const handleLogout = async () => {
      console.log("Logout function started");
  
      try {
        const token = localStorage.getItem("token");
        const config = {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the request header
          },
        };
  
        const url = "https://api.mbasolutions.co/api/logout";
        const res = await axios.post(url, {}, config); // Include an empty object as the request body
        console.log(res.data.message); // Use `res.data` to access the response data
        localStorage.removeItem("token");
        window.location.reload();
      } catch (error) {
        if (error.response && error.response.status >= 400 && error.response.status <= 500) {
          console.log(error.response.data.message);
        }
      }
    };
    const handleMenuItemClick = (title) => {
      const menuItem = Menu.find((item) => item.title === title);
      if (menuItem && menuItem.onClick) {
        menuItem.onClick();
      } else {
        Cookies.set('dashboardTitle', title, { expires: 1, path: '/' });
        toggleSidebar();
      }
    };

  const Menu = [
   
    { title: 'Add Team', icon: <MdPersonAdd /> },
    { title: 'Get Team', icon: <MdPeople /> },
    { title: 'Add Blogs', spacing: true, icon: <MdCreate /> },
    { title: 'Get Blogs', icon: <MdMailOutline /> },
    { title: 'Add Tracking data', spacing: true, icon: <MdTrackChanges /> },
    { title: 'Get Tracking data', icon: <MdSchool /> },
    { title: 'Add Client',  spacing: true, icon: <MdPersonAdd /> },
    { title: 'Get Clients', icon: <MdPeople /> },
    { title: 'Add Trainings', spacing: true, icon: <MdDashboard /> },
    { title: 'Get Trainings', icon: <MdLibraryBooks /> },
    { title: 'Add Jobs', spacing: true, icon: <MdWork /> },
  { title: 'Get Jobs', icon: <MdAssignment /> },
    { title: 'Add Service', spacing: true, icon: <MdAddAPhoto /> },
    { title: 'Get Service', icon: <MdList /> },    
    { title: 'Logout',spacing: true, icon: <MdExitToApp />,  onClick: handleLogout },
  ];

  return (
    <div className={`rubik ${sidebarVisible ? 'w-72' : 'hidden'} pb-20 bg-white min-h-screen shadow-xl duration-300 p-5 pt-8 relative`}>
      <div className="text-white bg-white border border-dark-purple cursor-pointer text-2xl flex justify-center items-center rounded-full absolute -right-3 top-9 p-2" onClick={toggleSidebar}>
        {sidebarVisible ? <MdArrowBack className="text-[#99702B]" /> : <MdArrowForward className="text-[#99702B]" />}
      </div>
      <div className="inline-block">
        <span className="text-4xl flex  justify-center items-center rounded cursor-pointer  mr-2"><img src={logo} id="logosize"  alt="leo"  height="80px" /></span>
      </div>

      <ul className="mt-12 ml-3 text-black/80">
        {Menu.map((menuItem, index) => [
          menuItem.spacing && <hr key={`hr-${index}`} className="border-t border-gray-100 my-5" />,
      
          <li
            key={index}
            className={`lightblack hover:underline ease-out transition-all duration-300 text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-light-white rounded-md mt-2`}
            onClick={() => handleMenuItemClick(menuItem.title)}
          >
            <span className="text-2xl block float-left">{menuItem.icon}</span>
            <span className={`text-base font-medium flex-1 ${!sidebarVisible ? 'hidden' : ''}`}>{menuItem.title}</span>
          </li>,
        ])}
      </ul>
    </div>
  );
};
