import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

const UpdatePosting = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [education, setEducation] = useState('');
  const [experience, setExperience] = useState('');
  const [apply, setApply] = useState('');
  const quillRef = useRef(null);

  // Retrieve the ID from local storage
  const updateJobId = localStorage.getItem('updateJobId');
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    const fetchJobPost = async () => {
      try {
        const response = await axios.get(`https://api.mbasolutions.co/api/jobs/getsinglejob/${updateJobId}`,config);
        const job = response.data;

        // Populate the form with the existing job data
        setTitle(job.title || '');
        setDescription(job.description || '');
        setEducation(job.education || '');
        setExperience(job.experience || '');
        setApply(job.apply || '');
      } catch (error) {
        console.error('Error fetching job post:', error);
      }
    };

    // Fetch the job post data when the component mounts
    fetchJobPost();
  }, [updateJobId]);

  const handleUpdateJobPost = async () => {
    try {
      const jobPostData = {
        title,
        description,
        education,
        experience,
        apply,
      };

      await axios.put(`https://api.mbasolutions.co/api/jobs/updatejob/${updateJobId}`, jobPostData,config);

      // Reset form after successful submission
      setTitle('');
      setDescription('');
      setEducation('');
      setExperience('');
      setApply('');

      // Remove the ID from local storage after updating
      localStorage.removeItem('updateJobId');

      // Show success alert
      Swal.fire({
        icon: 'success',
        title: 'Job post updated successfully!',
        showConfirmButton: false,
        timer: 1500,
      });
      window.location = "/admindashboard";
    } catch (error) {
      console.error('Error updating job post:', error);

      // Show error alert
      Swal.fire({
        icon: 'error',
        title: 'Error updating job post',
        text: 'Please try again.',
      });
    }
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (value) => {
    setDescription(value);
  };

  const handleEducationChange = (e) => {
    setEducation(e.target.value);
  };

  const handleExperienceChange = (e) => {
    setExperience(e.target.value);
  };

  const handleApplyChange = (e) => {
    setApply(e.target.value);
  };

  return (
    <>
      <h2 className='text-[22px] py-12 font-bold text-center'>Update Jobs</h2>

      <div className='max-w-md mx-auto mt-8 p-4 border border-gray-300 rounded-md'>
        <label className='block mb-2'>Title:</label>
        <input
          type='text'
          value={title}
          onChange={handleTitleChange}
          className='w-full p-2 border border-gray-300 rounded-md mb-4'
        />

        <label className='block mb-2'>Description:</label>
        <ReactQuill
          theme="snow"
          placeholder="Write Description....."
          value={description}
          onChange={handleDescriptionChange}
          className="w-[100%] py-1 h-40 max-h-fit"
          ref={quillRef}
        />

        <label className='block mb-2 mt-20'>Education:</label>
        <input
          type='text'
          value={education}
          onChange={handleEducationChange}
          className='w-full p-2 border border-gray-300 rounded-md mb-4'
        />

        <label className='block mb-2'>Experience:</label>
        <input
          type='text'
          value={experience}
          onChange={handleExperienceChange}
          className='w-full p-2 border border-gray-300 rounded-md mb-4'
        />

        <label className='block mb-2'>Apply at:</label>
        <textarea
          value={apply}
          onChange={handleApplyChange}
          className='w-full p-2 border border-gray-300 rounded-md mb-4'
        />

        <button
          onClick={handleUpdateJobPost}
          className='w-full p-2 bg-orange-500 hover:bg-orange-600 text-white rounded-md'
        >
          Update Job Post
        </button>

        <div className='font-semibold my-10 text-center'>
          Want to go to the Main Page of <Link to='/admindashboard' className='text-purple-500 underline'> Admin Dashboard</Link>
        </div>
      </div>
    </>
  );
};

UpdatePosting.modules = {
  toolbar: [
    [{ font: [] }],
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    ["link", "image"],
    ["code-block"],
    ["clean"],
  ],
};

UpdatePosting.formats = [
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "list",
  "bullet",
  "align",
  "color",
  "background",
  "link",
  "image",
  "code-block",
];

export default UpdatePosting;
