import React from "react";
import { Link } from "react-router-dom";
import {BsHouseDoor} from 'react-icons/bs'
import { animateScroll as scroll } from 'react-scroll';
import { useParams } from 'react-router-dom';


const PecbSection1 = () => {
  const handleLinkClick = () => {
    scroll.scrollToTop();
  };
  const { itemName } = useParams();
  return (
    <div>
      <div className="bg-sections w-full h-[50vh] flex justify-center ">
        <div className=" relative items-center flex flex-col justify-center shadow-white  text-white drop-shadow-md hover:drop-shadow-xl ">
          <h3 className="text-3xl font-bold ">PECB</h3>
          <div className="font-semibold text-lg pt-3 flex hover:text-gray-200 ">
          <Link
              to="/"
              className="cursor-pointer "
              onClick={handleLinkClick}
            >
              
          <BsHouseDoor size={22}/>
          </Link>
          
            <Link
              to="/"
              className=" cursor-pointer "
              onClick={handleLinkClick}
            >
              
              {" "}
               Home
            </Link>
            /
            <Link to="/Skill_Building" onClick={handleLinkClick}>
              <span className="text-gray-200 hover:text-gray-300 cursor-pointer ">
              Skill Building
              </span>{" "}
            </Link>{" "}
            <span>
              /{itemName}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PecbSection1;