// CreateBlog.jsx
import React, { useRef, useState } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone"; // Import useDropzone
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
const CreateBlog = () => {
  const [name, setName] = useState("");
  const [paragraph, setParagraph] = useState("");
  const [fileName, setFileName] = useState("");
  const [image, setImage] = useState(null);
  const quillRef = useRef(null);

  const handleTitleChange = (e) => {
    setName(e.target.value);
  };

  const handleImageDrop = (acceptedFiles) => {
    const selectedImage = acceptedFiles[0];
    setImage(selectedImage);
    setFileName(selectedImage.name);
  };
  const handleBody = (e) => {
    console.log(e);
    setParagraph(e);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleImageDrop,
  });

  const handleCreateBlog = async () => {
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("paragraph", paragraph);
      formData.append("image", image);
      formData.append("createdDate", new Date());
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      // Replace with your backend API endpoint
      const apiUrl = "https://api.mbasolutions.co/api/blogs/upload";

      await axios.post(apiUrl, formData,config);
      console.log(formData);
      Swal.fire({
        icon: "success",
        title: "Blog created successfully!",
        showConfirmButton: false,
        timer: 1500,
      });
      
      setName("");
      setParagraph("");
      setImage(null);
      setFileName("");
    } catch (error) {
      console.error("Error creating blog:", error);

      Swal.fire({
        icon: "error",
        title: "Error creating blog",
        text: "Please try again.",
      });
    }
  };
  return (
    <>
      <div className="rubik w-[90%]  md:w-[80%]   mt-32 mx-auto">
        <h2 className="text-[30px] drop-shadow-lg my-8 font-bold">
          Create a New Blog
        </h2>
        <div className="md:w-[70%] flex  flex-col" >
          <label className="text-[22px] font-bold mb-2">Title:</label>
          <input
            type="text"
            value={name}
            onChange={handleTitleChange}
            className=" w-[90%] py-1 h-16 border border-gray-400 "
          />
        </div>
        <div className="md:w-[70%] flex flex-col mt-4">
          <label className="text-[22px] font-bold mb-2">Description:</label>
          <ReactQuill
            theme="snow"
            placeholder="Write Description....."
            value={paragraph}
            onChange={handleBody}
            className="w-[90%] py-1 h-40 max-h-fit"
            ref={quillRef}
            modules={CreateBlog.modules}
            formats={CreateBlog.formats}
          />
        </div>
        <div className="md:w-[50%] flex flex-col mt-24">
          <label className="text-[22px] font-bold mb-2">Image:</label>
          <div {...getRootProps()} style={dropzoneStyles} className="h-40">
            <input {...getInputProps()} className="" />

            <p className="justify-center items-center flex my-12">
              Drag 'n' drop an image here, or click to select one
            </p>
          </div>
          <div className="text-green-600 mt-2">
            {image && <p className="mb-4 text-gray-800">{fileName}</p>}
          </div>
        </div>

        <button
          className="text-[22px] font-bold p-2 bg-orange-500 hover:bg-orange-600 transition-all ease-out duration-300 text-white border rounded-lg my-16  border-orange-500 "
          onClick={handleCreateBlog}
        >
          Create Blog
        </button>
      </div>
      
    </>
  );
};

const dropzoneStyles = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};
CreateBlog.modules = {
  toolbar: [
    [{ font: [] }],
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    ["link", "image"],
    ["code-block"],
    ["clean"],
  ],
};

CreateBlog.formats = [
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "list",
  "bullet",
  "align",
  "color",
  "background",
  "link",
  "image",
  "code-block",
];

export default CreateBlog;
