import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { animateScroll as scroll } from "react-scroll";
import { Link } from 'react-router-dom';

const GetJobPosting = () => {
  const [jobPostings, setJobPostings] = useState([]);
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const fetchJobPostings = async () => {
    try {
      const response = await axios.get('https://api.mbasolutions.co/api/jobs/getjob');
      setJobPostings(response.data);
    } catch (error) {
      console.error('Error fetching job postings:', error);
    }
  };

  useEffect(() => {
   
    fetchJobPostings();
  }, []);

  const handleUpdateJobPost = async (id) => {
    try {
      // Store the ID in local storage for the update component
      localStorage.setItem('updateJobId', id);
      // Redirect or open the update component
      // For example, you can use React Router to navigate to the update component
    } catch (error) {
      console.error('Error updating job post:', error);
    }
    scroll.scrollToTop();
  };

  const handleDeleteJobPost = async (id) => {
    try {
      const shouldDelete = await Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      });

      if (shouldDelete.isConfirmed) {
        await axios.delete(`https://api.mbasolutions.co/api/jobs/deletejob/${id}`, config);

        setJobPostings((prevPostings) => prevPostings.filter((post) => post._id !== id));

        Swal.fire({
          icon: 'success',
          title: 'Job post deleted successfully!',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      console.error('Error deleting job post:', error);

      Swal.fire({
        icon: 'error',
        title: 'Error deleting job post',
        text: 'Please try again.',
      });
    }
  };

  return (
    <div className="bg-gray-100 p-8">
      <h2 className="text-2xl font-bold mb-8 text-center">Job Postings</h2>
      {jobPostings.map((job) => (
        <div key={job._id} className="bg-white p-6 rounded-lg shadow-md mb-8 relative">
         <Link to="/updateposting">
          <FaEdit
            className="text-blue-500 hover:text-blue-600 absolute top-4 left-6 cursor-pointer"
            onClick={() => handleUpdateJobPost(job._id)}
            size={22}
          />
          </Link>
          <FaTrash
            className="text-red-500 hover:text-red-600 absolute top-4 right-6 cursor-pointer"
            onClick={() => handleDeleteJobPost(job._id)}
            size={22}
          />
          <h3 className="text-xl font-semibold mb-4 text-center pt-4">{job.title}</h3>
          <div className='flex'>
            <h3 className='font-bold pr-2'>Description:</h3>
            <div
              dangerouslySetInnerHTML={{ __html: job.description }}
              className="text-gray-700 font- mb-4 "
            />
          </div>
          <div className='flex'>
            <h3 className='font-bold pr-2'>Education: </h3>
            <p className="text-gray-700 mb-4 ">{job.education}</p>
          </div>
          <div className='flex'>
            <h3 className='font-bold pr-2'>Experience: </h3>
            <p className="text-gray-700 mb-4 ">{job.experience}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default GetJobPosting;
