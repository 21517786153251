import axios from "axios";
import React, { useState, useEffect } from "react";
import './pecb.css'
import { Link, useParams } from "react-router-dom";
import EnrollmentForm from "./EnrollmentForm"; 

const PecbSection3responsive = () => {
  const [course, setCourse] = useState([]);
 
  const [isEnrollmentFormOpen, setIsEnrollmentFormOpen] = useState(false);
  const { itemName } = useParams();
  console.log("itemName:", itemName);

  useEffect(() => {
    axios.get(`https://api.mbasolutions.co/api/getcoursedata`).then((res) => {
      console.log(res.data);
      setCourse(res.data);
    });
  }, []);
  const filteredCourses = course.filter(course => course.name === itemName);

  const handleEnrollClick = () => {
    // Open the enrollment form
    setIsEnrollmentFormOpen(true);
  };
  

  return (
    <div className="w-full overflow-x-auto  bg-[#F2F2F2]">
      
      {filteredCourses.map((course) => (
        <table className=" mt-16 text-[22px] w-full md:text-lg lg:hidden overflow-hidden ">
          <tbody className="mx-2 md:mx-28">
            <tr className="mb-4 ">
              <th className="pl-6 md:w-[25%] py-2  text-left">Start Date</th>
              <td className="md:w-[75%] py-2 text-right pr-6">{course.startdate}</td>
            </tr>
            <tr className="mb-4">
              <th className="pl-6 md:w-[25%] py-2  text-left">End Date</th>
              <td className="md:w-[75%] py-2 text-right pr-6">{course.enddate}</td>
            </tr>
            <tr className="mb-4">
              <th className="pl-6 md:w-[25%] py-2  text-left">Start Date</th>
              <td className="md:w-[75%] py-2 text-right pr-6">{course.fee}</td>
            </tr>
            <tr className="mb-4">
              <th className="pl-6 md:w-[25%] py-2  text-left">Start - End Time</th>
              <td className="md:w-[75%] py-2 text-right pr-6">{course.startendtime}</td>
            </tr>
            <tr className="mb-4">
              <th className="pl-6 md:w-[25%] py-2  text-left">Batch Type</th>
              <td className="md:w-[75%] py-2 text-right pr-6">{course.batchtype}</td>
            </tr>
            <tr className="mb-4">
              <th className="pl-6 md:w-[25%] py-2  text-left">Training Mode</th>
              <td className="md:w-[75%] py-2 text-right pr-6">{course.trainingmode}</td>
            </tr>
            <tr className="mb-4">
              <th className="pl-6 md:w-[25%] py-2  text-left">Batch Status</th>
              <td className="md:w-[75%] py-2 text-right pr-6">{course.batchstatus}</td>
            </tr>
            <tr className="mb-4">
              <td className="pl-6 md:w-[25%] py-2 "></td>
              <td className="md:w-[75%] py-2 ">
                <div key={course.id} className="">
                  {course.enroll ? (
                <div className="py-[5px]  flex justify-center hover:bg-orange-700 cursor-pointer mx-auto md:ml-[82%] rounded-md bg-orange-500 text-white text-[16px]" onClick={handleEnrollClick}>
                Enroll Open
              </div>
                  ) : (
                    <div className="py-[5px]  flex justify-center hover:bg-purple-700 cursor-pointer mx-auto md:ml-[82%] rounded-md bg-purple-500 text-white text-[16px]">
                      Enroll Closed
                    </div>
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      ))}
      {isEnrollmentFormOpen && (
        <EnrollmentForm onClose={() => setIsEnrollmentFormOpen(false)} />
      )}
    </div>
  );
};

export default PecbSection3responsive;
