import React, { useEffect, useRef } from "react";
import "./Home2.css";
import HomeSection1 from "./HomeSection1";
import HomeSection2 from "./HomeSection2";
import HomeSection3 from "./HomeSection3";
import HomeSection4 from "./HomeSection4";
import Navbar from "../../components/Navbar";
import Layout from "../../components/Layout";
import HomeSection1new from "./HomeSection1new";

const MainHome = () => {
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const windowHeight = window.innerHeight;
  //     const scrollHeight = document.documentElement.scrollHeight;
  //     const scrollTop = window.scrollY;
      
  //     // Calculate the bottom of the last section (assuming 3 sections)
  //     const lastSectionBottom = windowHeight * 3; // Adjust as needed
      
  //     // Check if the user has reached the bottom of the last section
  //     if (scrollTop + windowHeight >= lastSectionBottom) {
  //       // Scroll to the top
  //       window.scrollTo({
  //         top: 0,
  //         behavior: "smooth" // Optional: Add smooth scrolling behavior
  //       });
  //     }
  //   };

  //   // Add scroll event listener to window
  //   window.addEventListener("scroll", handleScroll);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  return (
    <Layout title={"MBA Solutions - Home "} >
     

<div className="bg-[#F2F2F2] overflow-x-hidden">
      {/* <HomeSection1  /> */}
<HomeSection1new />
      <HomeSection2  />
      <HomeSection4 />
      <HomeSection3  />
      </div>
      
    </Layout>
  );
};

export default MainHome;
