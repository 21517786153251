import React from 'react'
import compony1 from './assets/compony1.PNG';
import compony2 from './assets/compony2.PNG'
import compony3 from './assets/compony3.PNG'
import compony4 from './assets/compony4.PNG'
import compony5 from './assets/compony5.PNG'
import compony6 from './assets/compony6.PNG'
import compony7 from './assets/compony7.PNG'
import compony8 from './assets/compony8.PNG'
import compony9 from './assets/compony9.PNG'
import Team1 from './assets/Team/Team1.jpeg'
import Team2 from './assets/Team/Team2.jpeg'
import Team3 from  './assets/Team/Team3.jpeg'
import Team4 from './assets/Team/Team4.jpeg'
import Pic1 from './assets/TeamSlider/Pic1.jpg'
import Pic2 from './assets/TeamSlider/Pic2.jpg'
import Pic3 from './assets/TeamSlider/Pic3.jpg'
import Pic4 from './assets/TeamSlider/Pic4.jpg'



export const data = {

    company: [
        {
            img: compony1,
           
        },
        {
            img: compony2,
           
        },
        {
            img: compony3,
           
        },
        {
            img: compony4,
           
        },
        {
            img: compony5,
           
        },
        {
            img: compony6,
           
        },
        {
            img: compony7,
           
        },
        {
            img: compony8,
           
        },
         {
            img: compony9,
           
        },

    ],
    testominals:[
        {
            name:"Makson Packaging Director",
            comments:"Our experience with MBA Solutions Private Limited was exceptional. Their team's proficiency in ISO standards and commitment to client satisfaction were evident throughout our certification journey. MBA Solutions tailored their approach to our specific needs, providing valuable insights and ensuring a smooth certification process. We appreciate their dedication and recommend MBA Solutions as a reliable partner for ISO certification services."
        },
        {
            name:"James Wilson",
            comments:"This template has everything I was looking for my business website to have. From easy-to-customize pages to flawlessly working web tools, Wuart is my number one choice!"
        },
        {
            name:"Ann Williams",
            comments:"Thank you for such an affordable and multipurpose template! It's easy to configure and use and what's more important it looks great. It's just what I wanted for my website. Keep doing a great job, I'm sure to become a returning customer!"
        },
        {
            name:"Jane Smith",
            comments:"Since I'm not a real pro in web design, I love to use these ready-made and beautifully looking templates.They are available at very affordable prices and they save a lot of time,and deliver from a lot of sweat and tears!"
        }
    ],
    Numbers:[
        {
            innumber:45,
            name:"Awards",
            description:"For Best Performances",
       delay:2,
        },
        {
            innumber:100,
            name:"Projects Anually",
            description:"Creating the successful path",
            delay:2,
        },
        {
            innumber:1047,
            name:"Happy Customers",
            description:"Customers Response",
       delay:2,
        }
    ],

     TeamMembers : [
        {
        name:'Muhammad Atif',
          role: "CEO",
          image:Team1, 
          bio:"Our visionary CEO leading us to new horizons with a wealth of experience, innovative thinking, and a passion for driving our organization's success. "    
        },
        {
            name:'Zara Atif',
            role: "Director Marketing",
            image: Team4, 
            bio:"Our marketing director, crafting strategic excellence"
       },
        {
        name:'Syed Shahzad Ali shah',
          role: "Head Of Marketing",
          image: Team2, 
          bio:"Our marketing maestro shaping brand success."
        },
        {
        name:'Syed Shahzad Ali shah',
          role: "Operational Manager",
          image: Team3, 
          bio:"Our operational guru ensuring seamless efficiency"
        },
       
      ],
      TeamSlider:[
            { img:Pic1,name:"Pic1" },
            { img:Pic2,name:"Pic2" },
            { img:Pic3,name:"Pic3" },
            { img:Pic4,name:"Pic4" }
      ]

   
}