import React from "react";
import "../Home/Home.css";
import AboutSection1 from "./AboutSection1";
import AboutSecion2 from "./AboutSecion2";
import AboutSection3 from "./AboutSection3";


import Layout from "../../components/Layout";
import Team from "./Team";
import IndustrialCoverage from "./IndustrialCoverage";
import ContactSection4 from "../contact/ContactSection4";

const About = () => {
  return (
    <Layout title={"MBA Solutions - About us "} keywords={"About Us,Our Mission,Our Vission, Industrial Coverage, MBA Industrial Coverage, Team, Our Team, Meet the Team, MBA Team, Professional Team, Team Members,Mba Solutions,Muhammad Atif, Professional Industrial Coverage, Business Solutions, MBA Consulting, Service Offerings, Mba Solutions,ISO,pecb, certification"}>
   <div className="bg-[#F2F2F2] ">
      <div className=" ">
    
        <AboutSection1 />
      </div>
      <div > 
      <AboutSecion2 />
     
      </div>
     <div >
      <AboutSection3 />
     </div>
      
     <div>
      <Team />
     </div>
     
     <div>
      <IndustrialCoverage />
     </div>
     <div className="">
      <ContactSection4 />
     </div>
     </div>
    </Layout>
  );
};

export default About;
