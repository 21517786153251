import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import ScrollTrigger from 'react-scroll-trigger';

const AboutSection3 = () => {
  const [counterOn, setCounterOn] = useState(false);

  const textSpring = useSpring({
    opacity: counterOn ? 1 : 0,
    transform: counterOn ? "translateX(0)" : "translateX(-50%)",
    from: { opacity: 0, },
  });

  return (
    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>

      <div className="mt-28">
        <h3 className=" text-3xl font-bold text-center pb-20 drop-shadow-2xl">
          Our Mission
        </h3>
        <animated.p className="mb-16 mx-6 text-center" style={textSpring}>
          MBA Solutions working as a neutral & independent certification agency
          for assessment, training, product, certification, and other areas in
          order to deliver economic values to customers & assist in the
          management of their assets, projects, products, and systems, resulting
          in performance improvement & risk reduction to support industry and
          the public sector by providing innovative, solution-driven,
          professional services. MBA Solutions have a vision of Quality
          Transformation through its Services.
        </animated.p>
      </div>
    </ScrollTrigger>
  );
};

export default AboutSection3;
