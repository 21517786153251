import React, { useState, useEffect } from 'react';
import Axios from 'axios';

const Section1 = () => {
  const [governance, setGovernance] = useState(null);

  useEffect(() => {
    // Make the Axios GET request to fetch data
    Axios.get('https://api.mbasolutions.co/api/categories/get')
      .then((response) => {
        // Filter the data to find the object with name: "Auditing"
        const Govereness = response.data.find(item => item.name === "Governance, Risk and Compliance");

        // Set the auditing data in the state
        setGovernance(Govereness);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <div className='my-8 mt-16 md:mx-10 mx-4 '>
      {governance ? (
        <div>
          
          <h1 className='text-[32px] font-bold drop-shadow-lg text-center'> {governance.name}</h1>
          {/* Add more data properties here */}

  <div className={`flex justify-center item-center  ${governance.image?'my-20':'mb-20'
  }` }>
          <img src={`https://api.mbasolutions.co${governance.image}`} className="max-w-full rounded-md mx-auto max-h-[70vh]" alt='' />
          </div>
          <p className='mb-12 text-center'>{governance.paragraph}</p>


        </div>
        
      ) : (
        <p>Loading...</p>
      )}

     
    </div>
  );
}

export default Section1;
