import React from 'react'
import ToolkitMain from './ToolkitMain'
import Layout from '../../components/Layout'
import ToolkitSection1 from './ToolkitSection1'
const Toolkits = () => {
  return (
    <Layout title={"MBA Solutions - Toolkits "} keywords={"Toolkits, MBA Toolkits, Business Toolkits, MBA Resources, Toolkit Downloads,mbasolutions,iso,iso 1901"}>
<ToolkitSection1 />
<div className='bg-[#F2F2F2] py-20'>
        <ToolkitMain  />
        </div>
    
    </Layout>
  )
}

export default Toolkits