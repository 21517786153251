import React from 'react';
import {Link} from 'react-router-dom';
const Success = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-green-100">
      <div className="bg-white p-10 rounded-lg shadow-lg text-center">
        <svg
          className="w-16 h-16 text-green-500 mx-auto mb-4"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <h2 className="text-2xl font-bold mb-2">Payment Successful</h2>
        <p className="text-gray-600 mb-4">Thank you for your purchase!</p>
        <Link to='/'>
        <button className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
          Back to Home
        </button>
        </Link>
      </div>
    </div>
  );
};

export default Success;
