import React from 'react'

const pecbSection2 = () => {
  return (
    <div className='mx-7 my-4 md:mx-14'>

    <h2 className='title pt-28 text-3xl text-center font-bold '>Overview</h2>
    <p className='text-gray-800 montserrat pt-12 '>Adhering to international standards and regulations has become a norm for businesses to run their businesses worldwide. In this regard, Professional Evaluation and Certification Board (PECB) is one of the top accreditation bodies that provides certification for professionals and enterprise systems across various international standards. There are various categories across which PECB provides its certification training, which include Risk Management, Quality Management Systems, Service Management, Information Security management, and Business Continuity Management.</p>

<p className='text-gray-600 montserrat pt-8'>Check out some of the most popular PECB certification courses below to enrol today.</p>
    </div>
  )
}

export default pecbSection2