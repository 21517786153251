import React from 'react'
import ServicesSection2 from './ServicesSection2'
import Layout from '../../components/Layout'

const Services = () => {
  return (
                <div className='pt-16 bg-[#F2F2F2]'>
                 
      <ServicesSection2 />
     
      </div>
 
  )
}

export default Services