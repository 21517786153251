import React, { useState, useEffect } from 'react';
import { RiSearchLine } from 'react-icons/ri';
import Axios from 'axios';

const TrackingSection2 = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [searchStatus, setSearchStatus] = useState('initial');

  useEffect(() => {
    // Make the Axios GET request to fetch data
    Axios.get('https://api.mbasolutions.co/api/tracking/get')
      .then((response) => {
        setData(response.data); // Set the data in state
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  // Filter data based on the search term
  const handleSearch = () => {
    setSearchStatus('searching'); // Set search status to 'searching'

    // Simulate an asynchronous search operation
    setTimeout(() => {
      const filteredData = data.filter(
        (person) =>
          person.serialnumber.toLowerCase() === searchTerm.toLowerCase()
      );

      if (filteredData.length === 0) {
        setSearchStatus('notFound'); 
      } else {
        setSearchedData(filteredData);
        setSearchStatus('found'); 
      }
    }, 1000); 
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  }
  return (
    <div className='mb-32 mt-24 px-3'>
      <h2 className='text-[34px] text-center font-bold drop-shadow-md my-6 '>Tracking</h2>
      <p className='text-center'>Efficiently monitor and trace document movements through our comprehensive tracking system for seamless management</p>
      <h6 className='mt-16  text-center font-semibold  text-[20px] mb-6'>Search Your Document By Serial Number</h6>
      <div className="flex items-center justify-center">
        <input
          type="text"
          placeholder="Enter Serial Number..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyPress={handleKeyPress}
          className="py-2 px-4 border w-[90%] md:w-[70%] flex border-gray-300 rounded-l-md focus:outline-none focus:border-orange-500"
        />
        <button
          className="bg-orange-500 text-white px-4 py-[0.82rem] rounded-r-md"
          onClick={handleSearch}
        >
          <RiSearchLine />
        </button>
      </div>
      {searchStatus === 'initial' && (
        <p className="text-center text-red-500 font-bold mt-28">
          Enter serial number to verify your document
        </p>
      )}
      {searchStatus === 'searching' && (
        <p className="text-center mt-2">Searching...</p>
      )}
      {searchStatus === 'notFound' && (
        <p className="text-center text-red-500 font-bold mt-2">
          Document not found
        </p>
      )}
      {searchStatus === 'found' && (
  <div className='my-24 mx-6 md:mx-10'>
    <table className="w-full border-collapse border border-gray-300">
      <thead>
        <tr>
          <th className="border border-gray-300 px-4 py-2">Serial Number</th>
          <th className="border border-gray-300 px-4 py-2">Name</th>
          <th className="border border-gray-300 px-4 py-2">Document</th>
        </tr>
      </thead>
      <tbody>
        {searchedData.map((person) => (
          <tr key={person._id} className="text-center">
            <td className="border border-gray-300 px-4 py-2">{person.serialnumber}</td>
            <td className="border border-gray-300 px-4 py-2">{person.name}</td>
            <td className="border border-gray-300 px-4 py-2">
              <a
                href={`https://api.mbasolutions.co${person.image}`}
                download={`https://api.mbasolutions.co${person.image}`}
                className="text-blue-500 hover:underline"
              >
                Download File
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)}

      
     
    </div>
  );
};

export default TrackingSection2;
