import React, { useState } from 'react'
import { SideNavbar } from './Navbar/SideNavbar';
import DashboardNav from './Navbar/DashboardNav'
import AddTeam from '../Team/AddTeam';
import GetTeam from '../Team/GetTeam';
import GetServiceData from '../ServiceCategory/GetServiceData';
import AddServiceData from '../ServiceCategory/AddServiceData';
import CreateBlog from '../Blogs/CreateBlog';
import AdminBlogList from '../Blogs/AdminBlogList';
import Get from '../crud/Get';
import Add from '../crud/Add';
import GetTracking from '../Tracking/GetTracking';
import AddTracking from '../Tracking/AddTracking';
import Cookies from 'js-cookie';
import AddClient from '../Client/AddClient';
import GetClient from '../Client/GetClient';
import CreatePosting from '../JobsPosting/CreatePosting';
import GetJobPosting from '../JobsPosting/GetJobPosting';


// import AllUsers from '../AllUsers/AllUsers';
// import AddProducts from '../AddPRoducts/AddProducts'
// import GetProducts from '../GetProducts/GetProducts';
const Dashboard = () => {
  const [sidebarVisible, setSidebarVisible] = useState(true);
 
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };
  const  title= Cookies.get('dashboardTitle');
  return (
    <>
      <DashboardNav onToggleSidebar={toggleSidebar} />
      <div className="flex">
        {sidebarVisible && (
          <div className='z-[17000] absolute top-0'>
            <SideNavbar onToggleSidebar={toggleSidebar} />
          </div>
        )}
      </div>
<div className={`py-7 px-2 lg:p-7 bg-[#f2f2f2]  min-h-[100vh] rubik`}>
      <div className={` ${sidebarVisible?'md:ml-72 mx-2' : ''}`}>
        <h1 className="text-[30px] drop-shadow-md font-semibold text-center my-10">{title}</h1>
        {
          
          title==='Add Team'?<AddTeam />:title==='Get Team'?<GetTeam />:title==='Add Blogs'?<CreateBlog/>:title==="Get Blogs"?<AdminBlogList />:title==='Add Tracking data'?<AddTracking />:title==='Get Tracking data'?<GetTracking />:title==='Add Service'?<AddServiceData/>:title==='Get Service'?<GetServiceData />:title==='Add Trainings'?<Add />:title==='Get Trainings'?<Get />:title==='Add Client'?<AddClient />:title==='Get Clients'?<GetClient />:title==='Add Jobs'?<CreatePosting />:title==='Get Jobs'?<GetJobPosting />:"Dashboard"
        }
      </div>
      </div>
    </>
  )
}

export default Dashboard