import React, { useEffect, useState } from 'react';
import { ErrorMessage, useField } from 'formik';
import Swal from 'sweetalert2';

export const FormAuthenticationmessage = ({ label, fileInput, textarea, ...props }) => {
  const [field, meta] = useField(props);

  // Define a state variable for file input value and its setter
  const [fileInputValue, setFileInputValue] = useState(null);

  const inputComponent = textarea ? (
    <textarea
      className={`form-control shadow-none border border-gray-700 ${meta.touched && meta.error && 'is-invalid'}`}
      {...field}
      {...props}
      autoComplete="off"
    />
  ) : props.as === 'select' ? (
    <select
      className={`form-control shadow-none border border-gray-700 ${meta.touched && meta.error && 'is-invalid'}`}
      {...field}
      {...props}
    />
  ) : fileInput ? (
    <div>
      <label htmlFor={field.name}>{label}</label>
      <input
        type="file"
        className={`form-control shadow-none border border-gray-700 ${meta.touched && meta.error && 'is-invalid'}`}
        onChange={(e) => {
          setFileInputValue(e.currentTarget.files[0]); // Update the state with the selected file
          field.onChange(e); // Trigger formik's onChange
        }}
        onBlur={field.onBlur} // Trigger formik's onBlur
        autoComplete="off"
      />
    </div>
  ) : (
    <input
      className={`form-control shadow-none border border-gray-700 ${meta.touched && meta.error && 'is-invalid'}`}
      {...field}
      {...props}
      autoComplete="off"
    />
  );

  // Function to display an alert when an error occurs
  const showAlert = () => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Fill all Required Data',
    });
  };

  // Use useEffect to show the alert when an error occurs
  useEffect(() => {
    if (meta.touched && meta.error) {
      showAlert(); // Show the alert when an error occurs
    }
  }, [meta.touched, meta.error]);

  return (
    <div className="mb-2">
      <label htmlFor={field.name}>{label}</label>
      {inputComponent}
      <ErrorMessage component="div" name={field.name} className="error text-red-600 text-[13px]" />
    </div>
  );
};
