import { useEffect, useState } from "react";
import axios from "axios";
import React from "react";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
function Get() {
  const [course, setCourse] = useState([]);
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    useEffect(() => {
      // Get the token from localStorage
  
      // Make the GET request with the token in the headers
      axios
        .get(`https://api.mbasolutions.co/api/getcourse`,config)
        .then((res) => {
          console.log(res.data);
          setCourse(res.data);
        });
    }, []);
  

  const editHandler = (course) => {
    console.log(course);
  };

  const setData = (course) => {
    let {
      _id,
      name,
      fee,
      startdate,
      enddate,
      startendtime,
      batchtype,
      trainingmode,
      batchstatus,
      enroll,
    } = course;
    localStorage.setItem("ID", _id);
    localStorage.setItem("Name", name);
    localStorage.setItem("Fee", fee);
    localStorage.setItem("Start Date", startdate);
    localStorage.setItem("Start Date", startdate);
    localStorage.setItem("End Date", enddate);
    localStorage.setItem("Start to End Date", startendtime);
    localStorage.setItem("Batch Type", batchtype);
    localStorage.setItem("Training Mode", trainingmode);
    localStorage.setItem("Batch Status", batchstatus);
    localStorage.setItem("Enroll", enroll);
  };

  const getData = (data) => {
    axios
      .get(`https://api.mbasolutions.co/api/getcourse`, config)
      .then((getData) => {
        setCourse(getData.data);
      });
  };

  const deleteData = async (_id) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const confirmed = await Swal.fire({
        title: "Are you sure?",
        text: "This action cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (confirmed.isConfirmed) {
        await axios.delete(
          `https://api.mbasolutions.co/api/deletecourse/${_id}`,
          config
        );

        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Data has been deleted successfully",
        });

        getData();
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error in Deleting Data.Please Try again later.",
      });
      console.log("Error: " + error);
    }
  };

  return (
    <div className="">
      <div className="overflow-x-auto overflow-y-auto">
        <table
          className="table text-lg mx-auto border-none my-8 w-full md:w-[97%] overflow-auto"
          border="1"
        >
          <thead className="text-center">
  <tr>
    <th className="mb-4 min-w-[150px]">Name</th>
    <th className="mb-4 min-w-[150px]">Fee</th>
    <th className="mb-4 min-w-[150px]">Start Date</th>
    <th className="mb-4 min-w-[150px]">End Date</th>
    <th className="mb-4 min-w-[150px]">Start to End Date</th>
    <th className="mb-4 min-w-[150px]">Batch Type</th>
    <th className="mb-4 min-w-[150px]">Training Mode</th>
    <th className="mb-4 min-w-[150px]">Batch Status</th>
    <th className="mb-4 min-w-[150px]">Actions</th>
  </tr>
</thead>


          <tbody className="mt-4 text-center">
            {course.map((course) => (
              <tr key={course._id}>
                 <td className="mt-2 md:mt-8">{course.name}</td>
                <td className="mt-2 md:mt-8">{course.fee}</td>
                <td className="mt-2 md:mt-8">{course.startdate}</td>
                <td className="mt-2 md:mt-8">{course.enddate}</td>
                <td className="mt-2 md:mt-8">{course.startendtime}</td>
                <td className="mt-2 md:mt-8">{course.batchtype}</td>
                <td className="mt-2 md:mt-8">{course.trainingmode}</td>
                <td className="mt-2 md:mt-8">{course.batchstatus}</td>
                <td className="mt-2 md:mt-8">
                  <NavLink to="/admintrainingupdate">
                    <button
                      className="btn text-white rounded-md py-2 px-4 md:px-6 mt-2 bg-sky-500 hover:bg-sky-600"
                      onClick={() => setData(course)}
                    >
                      Edit
                    </button>
                  </NavLink>
                  <button
                    className="btn py-2  ml-4 px-4 md:px-6 mt-2 rounded-md text-white bg-red-600 hover:bg-red-500"
                    onClick={() => deleteData(course._id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
     
    </div>
  );
}

export default Get;
