import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { FormAuthenticationmessage } from "./FormAuthenticationmessage";
import axios from "axios";
import { animateScroll as scroll } from "react-scroll";
import Swal from "sweetalert2";
import { IoMdPerson } from "react-icons/io";
import { FaMapMarkerAlt,FaGraduationCap,FaStar } from "react-icons/fa";
import { RiSuitcaseFill } from "react-icons/ri";

const JobSection2 = () => {
  const handleLinkClick = () => {
    scroll.scrollToTop();
  };
  const validate = Yup.object({
    name: Yup.string().required("Name is required"),
    dob: Yup.string().required("Date of Birth is required"),
    cob: Yup.string().required("Country of Birth is required"),
    cityofbirth: Yup.string().required("City of Birth is required"),
    cnic: Yup.string().required("CNIC is required"),
    maritalstatus: Yup.string().required("Marital Status is required"),
    gender: Yup.string().required("Gender is required"),
    religion: Yup.string().required("Religion is required"),
    disability: Yup.string().required("Disability status is required"),
    number: Yup.number().required("Phone number is required"),
    address: Yup.string().required("Address is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    refered: Yup.string().required("Referral information is required"),
    cityapplying: Yup.string().required("City applying is required"),
    degree: Yup.string().required("Degree is required"),
    majors: Yup.string().required("Majors is required"),
    qualificationyear: Yup.string().required("Qualification year is required"),
    tuition: Yup.string().required("Tuition information is required"),
    experience: Yup.string().required("Experience is required"),
    position: Yup.string().required("Position is required"),
    recentcompany: Yup.string().required("Recent company is required"),
    cnicexpiry: Yup.string().required("CNIC Expiry is required"),
  });

  return (
    <div>
      <Formik
        initialValues={{
          name: "",
          dob: "",
          cob: "",
          cityofbirth: "",
          cnic: "",
          maritalstatus: "",
          gender: "",
          religion: "",
          disability: "",
          number: "",
          address: "",
          country: "",
          state: "",
          city: "",
          refered: "",
          cityapplying: "",
          degree: "",
          majors: "",
          qualificationyear: "",
          tuition: "",
          experience: "",
          position: "",
          recentcompany: "",
          cnicexpiry: "",
        }}
        validationSchema={validate}
        onSubmit={async (values, { resetForm }) => {
         
          try {
            Swal.fire({
              title: "Sending Data",
              text: "Please wait...",
              icon: "info",
              allowOutsideClick: false,
              showConfirmButton: false,
              showCancelButton: false,
              didOpen: () => {
                Swal.showLoading();
              },
            });
            await validate.validate(values, { abortEarly: false }); // Run validation for all fields

            // If the form is valid, proceed with submitting the data
            resetForm();
            const response = await axios.post(
              "http://api.mbasolutions.co/api/jobform",
              values
            );
              
            // Show success alert
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "Thanks for Applying",
            });
          } catch (error) {
            console.error(error);

            // If the form is not valid, show an alert error
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Network Error.Please Try Again Later.",
            });
          }
        }}
      >
        {(formik) => (
          <div className=" w-[90%] mb-12 mx-auto  py-8 mt-4 rounded-3xl">
          

            <Form className="mx-7 md:mx-11 text-[#4c4d56] mt-16 rubik">
            <h1 className="my-16  w-fit px-12 mx-auto h-14  flex  items-center justify-center text-center shadow shadow-gray-400 rounded-lg  font-bold text-lg md:text-xl lg:text-2xl">
           <span><IoMdPerson  size={22} className="mr-2"/></span>   Personal Information
            </h1>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 md:gap-x-8 w-[100%] ">
                <div className="mb-5">
                  <label htmlFor="name">Full Name*</label>
                  <FormAuthenticationmessage
                    type="text"
                    id="name"
                    name="name"
                    className="form-control py-3 border text-[#415674] text-[13px] w-[100%]  border-slate-300 my-3 rounded-md outline-blue-400 px-3 "
                    
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="dob">Date of Birth*</label>
                  <FormAuthenticationmessage
                    type="date"
                    id="dob"
                    name="dob"
                    className="form-control py-3 border text-[#415674] text-[13px] w-[100%]  border-slate-300 my-3 rounded-md outline-blue-400 px-3 "
                  />
                </div>
                <div>
                  <label htmlFor="cob">Country of Birth*</label>
                  <FormAuthenticationmessage
                    type="text"
                    id="cob"
                    name="cob"
                    className="form-control py-3 border text-[#415674] text-[13px] w-[100%]  border-slate-300 my-3 rounded-md outline-blue-400 px-3 "
                  />
                </div>
                <div>
                  <label htmlFor=" cityofbirth">City of Birth*</label>
                  <FormAuthenticationmessage
                    type="text"
                    id="cityofbirth"
                    name="cityofbirth"
                    className="form-control py-3 border text-[#415674] text-[13px] w-[100%]  border-slate-300 my-3 rounded-md outline-blue-400 px-3 "
                  />
                </div>
                <div>
                  <label htmlFor="cnic">CNIC*</label>
                  <FormAuthenticationmessage
                    type="text"
                    id="cnic"
                    name="cnic"
                    className="form-control py-3 border text-[#415674] text-[13px] w-[100%]  border-slate-300 my-3 rounded-md outline-blue-400 px-3 "
                  />
                </div>
                <div>
                  <label htmlFor="cnicexpiry">CNIC Expiry Date*</label>
                  <FormAuthenticationmessage
                    type="date"
                    id="cnicexpiry"
                    name="cnicexpiry"
                    className="form-control py-3 border text-[#415674] text-[13px] w-[100%]  border-slate-300 my-3 rounded-md outline-blue-400 px-3 "
                  />
                </div>

                <div>
                  <label>Marital Status *</label>
                  <FormAuthenticationmessage
                    as="select"
                    name="maritalstatus"
                    id="maritalstatus"
                   
                    className="py-3 border text-[#415674] text-[13px] w-[100%] border-[#F2F2F2] my-3 rounded-md outline-blue-400 px-3"
                  >
                    <option value="">
                      --Select Marital Status--
                    </option>
                    <option value="married">Married</option>
                    <option value="single">Single</option>
                  </FormAuthenticationmessage>
                </div>
                <div>
                  <label>Gender *</label>
                  <FormAuthenticationmessage
                    as="select"
                    name="gender"
                    id="gender"
                   
                    className="py-3 border text-[#415674] text-[13px] w-[100%] border-[#F2F2F2] my-3 rounded-md outline-blue-400 px-3"
                  >
                    <option  value="">
                      --Select Gender--
                    </option>
                   
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">other</option>
                  </FormAuthenticationmessage>
                </div>
                <div className="">
                  <label htmlFor="religion">Religion*</label>
                  <FormAuthenticationmessage
                    type="text"
                    id="religion"
                    name="religion"
                    className="form-control py-3 my-3 border text-[#415674] text-[13px] w-[100%]  border-slate-300 mb-5 rounded-md outline-blue-400 px-3 "
                  />
                </div>
                <div>
                  <label htmlFor="disability">Disability*</label>
                  <FormAuthenticationmessage
                    type="text"
                    id="disability"
                    name="disability"
                    className="form-control py-3 border text-[#415674] text-[13px] w-[100%]  border-slate-300 my-3 rounded-md outline-blue-400 px-3 "
                  />
                </div>
              </div>
              <h1 className="my-16  w-fit px-12 mx-auto h-14  flex items-center justify-center text-center shadow shadow-gray540  rounded-lg font-bold text-lg md:text-xl lg:text-2xl">
           <span><FaMapMarkerAlt   size={22} className="mr-2"/></span>   Address
            </h1>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 md:gap-x-8 w-[100%] ">
                <div className="mb-5">
                  <label htmlFor="number">Phone*</label>
                  <FormAuthenticationmessage
                    type="text"
                    id="number"
                    name="number"
                    className="form-control py-3 border text-[#415674] text-[13px] w-[100%]  border-slate-300 my-3 rounded-md outline-blue-400 px-3 "
                    
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="address">Address*</label>
                  <FormAuthenticationmessage
                    type="text"
                    id="address"
                    name="address"
                    className="form-control py-3 border text-[#415674] text-[13px] w-[100%]  border-slate-300 my-3 rounded-md outline-blue-400 px-3 "
                  />
                </div>
                <div>
                  <label htmlFor="country">Country*</label>
                  <FormAuthenticationmessage
                    type="text"
                    id="country"
                    name="country"
                    className="form-control py-3 border text-[#415674] text-[13px] w-[100%]  border-slate-300 my-3 rounded-md outline-blue-400 px-3 "
                  />
                </div>
                <div>
                  <label htmlFor="state">State*</label>
                  <FormAuthenticationmessage
                    type="text"
                    id="state"
                    name="state"
                    className="form-control py-3 border text-[#415674] text-[13px] w-[100%]  border-slate-300 my-3 rounded-md outline-blue-400 px-3 "
                  />
                </div>
                <div>
                  <label htmlFor="city">City*</label>
                  <FormAuthenticationmessage
                    type="text"
                    id="city"
                    name="city"
                    className="form-control py-3 border text-[#415674] text-[13px] w-[100%]  border-slate-300 my-3 rounded-md outline-blue-400 px-3 "
                  />
                </div>
               
              </div>
              <h1 className="my-16  w-fit px-12 mx-auto h-14  flex items-center justify-center text-center shadow shadow-gray540  rounded-lg font-bold text-lg md:text-xl lg:text-2xl">
           <span><RiSuitcaseFill   size={22} className="mr-2"/></span>   Job Posting
            </h1>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 md:gap-x-8 w-[100%] ">
             
                <div className="form-group">
                  <label htmlFor="refered">How were you refered to us?*</label>
                  <FormAuthenticationmessage
                    type="type"
                    id="refered"
                    name="refered"
                    className="form-control py-3 border text-[#415674] text-[13px] w-[100%]  border-slate-300 my-3 rounded-md outline-blue-400 px-3 "
                  />
                </div>
                
                <div>
                  <label htmlFor="cityapplying"> City Applying For*</label>
                  <FormAuthenticationmessage
                    type="text"
                    id="cityapplying"
                    name="cityapplying"
                    className="form-control py-3 border text-[#415674] text-[13px] w-[100%]  border-slate-300 my-3 rounded-md outline-blue-400 px-3 "
                  />
                </div>

               
              </div>
              <h1 className="my-16  w-fit px-12 mx-auto h-14  flex items-center justify-center text-center shadow shadow-gray540  rounded-lg font-bold text-lg md:text-xl lg:text-2xl">
           <span><FaGraduationCap    size={22} className="mr-2"/></span>   Qualification
            </h1>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 md:gap-x-8 w-[100%] ">
                <div className="mb-5">
                  <label htmlFor="degree">Degree*</label>
                  <FormAuthenticationmessage
                    type="degree"
                    id="degree"
                    name="degree"
                    className="form-control py-3 border text-[#415674] text-[13px] w-[100%]  border-slate-300 my-3 rounded-md outline-blue-400 px-3 "
                    
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="majors">Majors*</label>
                  <FormAuthenticationmessage
                    type="type"
                    id="majors"
                    name="majors"
                    className="form-control py-3 border text-[#415674] text-[13px] w-[100%]  border-slate-300 my-3 rounded-md outline-blue-400 px-3 "
                  />
                </div>
                <div>
                  <label htmlFor="qualificationyear"> Qualification Year*</label>
                  <FormAuthenticationmessage
                    type="text"
                    id="qualificationyear"
                    name="qualificationyear"
                    className="form-control py-3 border text-[#415674] text-[13px] w-[100%]  border-slate-300 my-3 rounded-md outline-blue-400 px-3 "
                  />
                </div>
                <div>
                  <label htmlFor="tuition"> Tuition*</label>
                  <FormAuthenticationmessage
                    type="text"
                    id="tuition"
                    name="tuition"
                    className="form-control py-3 border text-[#415674] text-[13px] w-[100%]  border-slate-300 my-3 rounded-md outline-blue-400 px-3 "
                  />
                </div>
               
               
              </div>
              <h1 className="my-16  w-fit px-12 mx-auto h-14  flex items-center justify-center text-center shadow shadow-gray540  rounded-lg font-bold text-lg md:text-xl lg:text-2xl">
           <span><FaStar    size={22} className="mr-2"/></span> Experience
            </h1>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 md:gap-x-8 w-[100%] ">
                <div className="mb-5">
                  <label htmlFor="experience">Total Years of Experience*</label>
                  <FormAuthenticationmessage
                    type="text"
                    id="experience"
                    name="experience"
                    className="form-control py-3 border text-[#415674] text-[13px] w-[100%]  border-slate-300 my-3 rounded-md outline-blue-400 px-3 "
                    
                  />
                </div>
                
                <div>
                  <label htmlFor="position"> Recent Position*</label>
                  <FormAuthenticationmessage
                    type="text"
                    id="position"
                    name="position"
                    className="form-control py-3 border text-[#415674] text-[13px] w-[100%]  border-slate-300 my-3 rounded-md outline-blue-400 px-3 "
                  />
                </div>
                <div>
                  <label htmlFor="recentcompany"> Recent Company*</label>
                  <FormAuthenticationmessage
                    type="text"
                    id="recentcompany"
                    name="recentcompany"
                    className="form-control py-3 border text-[#415674] text-[13px] w-[100%]  border-slate-300 my-3 rounded-md outline-blue-400 px-3 "
                  />
                </div>
               
               
              </div>
              <button
                className="btn hover:shadow-xl bg-orange-500 hover:bg-orange-600 transition-all duration-200 ease-out text-white cursor-pointer mt-3 px-5 py-3 md:px-8 md:py-4 rounded-md text-xl "
                type="submit"
              >
                Submit
              </button>
            </Form>
          </div>
          
        )}
      </Formik>
    </div>
  );
};

export default JobSection2;
