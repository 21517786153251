import * as React from "react";
import { FaArrowRight } from 'react-icons/fa';
import { FaArrowLeft } from 'react-icons/fa';
const CustomLeftArrow = ({ onClick }) => (
  <i onClick={() => onClick()} className="custom-left-arrow" />
);
const CustomRightArrow = ({ onClick }) => {
  return <i className="custom-right-arrow" onClick={() => onClick()} />;
};

const CustomButtonGroup = ({ next, previous, goToSlide, carouselState }) => {
  const { totalItems, currentSlide } = carouselState;
  return (
    <div className="custom-button-group">
      <div>Current slide is {currentSlide}</div>
      <button onClick={() => previous()}>Previous slide</button>
      <button onClick={() => next()}>Next slide</button>
      <button
        onClick={() => goToSlide(Math.floor(Math.random() * totalItems + 1))}
      >
        Go to a random slide
      </button>
    </div>
  );
};
const CustomButtonGroupAsArrows = ({ next, previous }) => {
  return (
    
    <div className="absolute w-[95%]  justify-between items-center  hidden md:flex top-[46%]  ">
    
      <button onClick={previous}
            className="absolute   items-center ml-[-10px]    w-[40px] h-[40px]  flex justify-center rounded-[100%] text-black cursor-pointer hover:text-orange-500 text-[16px] my-auto border-2 border-black hover:border-orange-500"
           
          >
            <FaArrowLeft size={18}/>
          </button>
          <button onClick={next}
            className="items-center md:left-[92%] lg:left-[98%] lg:right-[-25px]  absolute   w-[40px] h-[40px]  flex justify-center rounded-[100%] text-black cursor-pointer hover:text-orange-500 text-[16px] my-auto border-2 border-black hover:border-orange-500
        "  >
                       <FaArrowRight size={18}/> 

          </button>
    </div>
    
  );
};

export {
  CustomLeftArrow,
  CustomRightArrow,
  CustomButtonGroup,
  CustomButtonGroupAsArrows,
};