import React, { useRef, useState } from 'react';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Swal from 'sweetalert2';

const CreatePosting = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [education, setEducation] = useState('');
  const [experience, setExperience] = useState('');
  const [apply, setApply] = useState('');
  const quillRef = useRef(null);
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const handleCreateJobPost = async () => {
    try {
      const jobPostData = {
        title,
        description,
        education,
        experience,
        apply,
      };

      await axios.post('https://api.mbasolutions.co/api/jobs/addjob', jobPostData,config);

      // Reset form after successful submission
      setTitle('');
      setDescription('');
      setEducation('');
      setExperience('');
      setApply('');

      // Show success alert
      Swal.fire({
        icon: 'success',
        title: 'Job post created successfully!',
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.error('Error creating job post:', error);

      // Show error alert
      Swal.fire({
        icon: 'error',
        title: 'Error creating job post',
        text: 'Please try again.',
      });
    }
  };

  return (
    <div className='max-w-md mx-auto mt-8 p-4 border border-gray-300 rounded-md'>
      <label className='block mb-2'>Title:</label>
      <input
        type='text'
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        className='w-full p-2 border border-gray-300 rounded-md mb-4'
      />

      <label className='block mb-2'>Description:</label>
      <ReactQuill
        theme="snow"
        placeholder="Write Description....."
        value={description}
        onChange={(value) => setDescription(value)}
        className="w-[100%] py-1 h-40 max-h-fit"
        ref={quillRef}
        modules={CreatePosting.modules}
        formats={CreatePosting.formats}
      />

      <label className='block mb-2 mt-20'>Education:</label>
      <input
        type='text'
        value={education}
        onChange={(e) => setEducation(e.target.value)}
        className='w-full p-2 border border-gray-300 rounded-md mb-4'
      />

      <label className='block mb-2'>Experience:</label>
      <input
        type='text'
        value={experience}
        onChange={(e) => setExperience(e.target.value)}
        className='w-full p-2 border border-gray-300 rounded-md mb-4'
      />

      <label className='block mb-2'>Apply at:</label>
      <textarea
        value={apply}
        onChange={(e) => setApply(e.target.value)}
        className='w-full p-2 border border-gray-300 rounded-md mb-4'
      />

      <button
        onClick={handleCreateJobPost}
        className='w-full p-2 bg-orange-500 hover:bg-orange-600 text-white rounded-md'
      >
        Create Job Post
      </button>
    </div>
  );
};
CreatePosting.modules = {
    toolbar: [
      [{ font: [] }],
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      ["link", "image"],
      ["code-block"],
      ["clean"],
    ],
  };
  
  CreatePosting.formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "align",
    "color",
    "background",
    "link",
    "image",
    "code-block",
  ];
export default CreatePosting;
