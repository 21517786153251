import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { FormAuthenticationmessage } from "./FormAuthenticationmessage";
import "./contact.css";
import axios from "axios";
import { animateScroll as scroll } from "react-scroll";
import Swal from "sweetalert2";

export const ContactSection3 = () => {
  const handleLinkClick = () => {
    scroll.scrollToTop();
  };
  const checkboxesdata = [
    { label: "Babies' Apparel ", value: "babiesApparel" },
    { label: "Bedding ", value: "bedding" },
    { label: "Carried Accessories ", value: "carriedAccessories" },
    { label: "Children's Apparel ", value: "childrensApparel" },
    { label: "Children's Denim Apparel ", value: "childrensDenimApparel" },
    { label: "Dyed Fabrics ", value: "dyedFabrics" },
    { label: "Dyed Fibers ", value: "dyedFibers" },
    { label: "Dyed Yarns ", value: "dyedYarns" },
    { label: "Fabrics ", value: "fabrics" },
    { label: "Filling-Stuffing ", value: "fillingStuffing" },
    { label: "Footwear ", value: "footwear" },
    { label: "Functional Accessories ", value: "functionalAccessories" },
    { label: "Greige Fabrics ", value: "greigeFabrics" },
    { label: "Greige Yarns ", value: "greigeYarns" },
    { label: "Hard Goods ", value: "hardGoods" },
    { label: "Home Textiles ", value: "homeTextiles" },
    { label: "Industrial or Technical ", value: "industrialTechnical" },
    { label: "Medical ", value: "medical" },
    { label: "Men's Apparel ", value: "mensApparel" },
    { label: "Men's Denim Apparel ", value: "mensDenimApparel" },
    { label: "Other ", value: "other" },
    { label: "Outdoor ", value: "outdoor" },
    { label: "Packaging ", value: "packaging" },
    { label: "Paper Products ", value: "paperProducts" },
    { label: "Personal Care, Hygiene ", value: "personalCareHygiene" },
    { label: "Printed Fabrics ", value: "printedFabrics" },
    {
      label: "Processed Post-Consumer Materials ",
      value: "processedPostConsumerMaterials",
    },
    {
      label: "Processed Pre-Consumer Materials ",
      value: "processedPreConsumerMaterials",
    },
    {
      label: "Reclaimed Post-Consumer Materials ",
      value: "reclaimedPostConsumerMaterials",
    },
    {
      label: "Reclaimed Pre-Consumer Materials ",
      value: "reclaimedPreConsumerMaterials",
    },
    { label: "Toys ", value: "toys" },
    { label: "Tops ", value: "tops" },
    { label: "Undyed Fabrics ", value: "undyedFabrics" },
    { label: "Undyed Fibers ", value: "undyedFibers" },
    { label: "Undyed Yarns ", value: "undyedYarns" },
    { label: "Unisex Apparel ", value: "unisexApparel" },
    { label: "Unisex Denim Apparel ", value: "unisexDenimApparel" },
    {
      label: "Unprocessed Post-Consumer Fibers/Materials ",
      value: "unprocessedPostConsumerFibersMaterials",
    },
    {
      label: "Unprocessed Pre-Consumer Fibers/Materials ",
      value: "unprocessedPreConsumerFibersMaterials",
    },
    { label: "Women's Apparel ", value: "womensApparel" },
    { label: "Women's Denim Apparel ", value: "womensDenimApparel" },
    { label: "Worn Accessories ", value: "wornAccessories" },
  ];
  const countryOptions = [
    "Russia", "Canada", "China", "United States", "Brazil", "Australia", "India", "Argentina", "Kazakhstan", "Algeria", "Democratic Republic of the Congo", "Greenland", "Saudi Arabia", "Mexico", "Indonesia", "Sudan", "Libya", "Iran", "Mongolia", "Peru", "Chad", "Niger", "Angola", "Mali", "South Africa", "Colombia", "Ethiopia", "Bolivia", "Mauritania", "Egypt", "Tanzania", "Nigeria", "Venezuela", "Pakistan", "Namibia", "Mozambique", "Zambia", "Burma (Myanmar)", "Afghanistan", "France", "Somalia", "Central African Republic", "Ukraine", "Madagascar", "Botswana", "Kenya", "Yemen", "Thailand", "Turkey", "Cameroon"
  ];
  // You can use the 'checkboxOptions' array in your code for checkboxes as needed.

  const checkboxValidationSchema = Yup.array()
    .min(1, "Select at least one option")
    .required("Select at least one option");

  const validate = Yup.object({
    companyName: Yup.string().required("Company name is Required"),
    address: Yup.string().required("Address is Required"),
    zip: Yup.string().required("Zip code is Required"),
    city: Yup.string().required("City is Required"),
    state: Yup.string().required("State/Province is Required"),
    country: Yup.string().required("Country is Required"),
    title: Yup.string().required("Title is Required"),
    firstName: Yup.string().required("First Name is Required"),
    lastName: Yup.string().required("Last Name is Required"),
    jobTitle: Yup.string().required("Job Title is Required"),
    telephoneNumber: Yup.string().required("Telephone Number is Required"),
    emailAddress: Yup.string().email("Invalid email address"),
    acceptPolices: Yup.boolean().oneOf([true], "Accept Policies is required"),
  });

  return (
    <Formik
      initialValues={{
        companyName: "",
        address: "",
        zip: "",
        city: "",
        state: "",
        country: "",
        title: "",
        firstName: "",
        lastName: "",
        jobTitle: "",
        telephoneNumber: "",
        emailAddress: "",
        checkboxes: [],
        certificationbody:"",
        question1: "",
        question2: "",
        question3: "",
        question4: "",
        question5: "",
        selectedCountry: "",
        acceptPolices:false,
      }}
      validationSchema={validate}
        validateOnBlur={true}
  validateOnChange={true}
        
        onSubmit={async (values, { resetForm }) => {
          try {
            Swal.fire({
              title: 'Sending Data',
              text: 'Please wait...',
              icon: 'info',
              allowOutsideClick: false,
              showConfirmButton: false,
              showCancelButton: false,
              didOpen: () => {
                Swal.showLoading();
              },
            });
            await validate.validate(values, { abortEarly: false }); // Run validation for all fields

          // If the form is valid, proceed with submitting the data
          resetForm();
          const response = await axios.post(
            "https://api.mbasolutions.co/certifications",
            values
          );
         
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Data has been sent successfully",
          });
        } catch (error) {
          console.error(error);

          // If the form is not valid, show an alert error
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Network Error.Please Try Again Later.",
          });
        }
      }}
    >
      {(formik) => (
        <div className=" w-full mx-auto overflow-x-hidden">
          <h1 className="mt-12 bg-white shadow-black/50   w-fit px-12 mx-auto max-h-fit md:h-16 flex items-center justify-center text-center shadow-lg  rounded-lg  font-bold text-lg md:text-xl lg:text-2xl">
            Your Process For Certificaton Starts Here!
          </h1>

          <Form className="mx-7  text-[#4c4d56] mt-24 md:mx-14 ">
            <div className="grid  mx-auto grid-cols-1 sm:grid-cols-2 gap-x-5 w-[100%] md:w-[90%]">
              <div className="mb-5">
                <label className="text-[#4c4d56] round" htmlFor="companyName">
                  Name of Your Company*
                </label>
                <FormAuthenticationmessage
                  type="text"
                  id="companyName"
                  name="companyName"
                  className=" py-3 border text-[#415674] text-[13px] w-[100%]   border-slate-300 my-3 rounded-md outline-blue-400 px-3 "
                  placeholder="Name"
                />
              </div>
              <div className="form-group">
                <label className="text-[#4c4d56]" htmlFor="address">
                  Address*
                </label>
                <FormAuthenticationmessage
                  type="text"
                  id="address"
                  name="address"
                  className=" py-3 border text-[#415674] text-[13px] w-[100%]  border-slate-300 my-3 rounded-md outline-blue-400 px-3 "
                  placeholder="Address"
                />
              </div>
              <div className="form-group">
                <label className="text-[#4c4d56]" htmlFor="zip">
                  ZIP*
                </label>
                <FormAuthenticationmessage
                  type="text"
                  id="zip"
                  name="zip"
                  className=" py-3 border text-[#415674] text-[13px] w-[100%]  border-slate-300 my-3 rounded-md outline-blue-400 px-3 "
                  placeholder="ZIP"
                />
              </div>
              <div className="form-group">
                <label className="text-[#4c4d56]" htmlFor="city">
                  City*
                </label>
                <FormAuthenticationmessage
                  type="text"
                  id="city"
                  name="city"
                  className=" py-3 border text-[#415674] text-[13px] w-[100%]  border-slate-300 my-3 rounded-md outline-blue-400 px-3 "
                  placeholder="City"
                />
              </div>
              <div className="form-group">
                <label className="text-[#4c4d56]" htmlFor="state">
                  State/Province*
                </label>
                <FormAuthenticationmessage
                  type="text"
                  id="state"
                  name="state"
                  className=" py-3 border text-[#415674] text-[13px] w-[100%]  border-slate-300 my-3 rounded-md outline-blue-400 px-3 "
                  placeholder="State/Province"
                />
              </div>
              <div className="form-group">
                <label className="text-[#4c4d56]" htmlFor="country">
                  Country*
                </label>
                <FormAuthenticationmessage
                  type="text"
                  id="country"
                  name="country"
                  className=" py-3 border text-[#415674] text-[13px] w-[100%]  border-slate-300 my-3 rounded-md outline-blue-400 px-3 "
                  placeholder="Country"
                />
              </div>
            </div>

            <div className="flex items-center justify-center md:ml-20 min-h-screen">
      <div className="w-full mx-auto  ">
        <div className="md:ml-48">
        <label className="text-[#4c4d56]" htmlFor="title">
          Title*
        </label>
              <FormAuthenticationmessage
                type="text"
                id="title"
                name="title"
                placeholder="Title"
                className=" py-3 border text-[#415674] text-[13px] w-[90%] border-slate-300 my-3 rounded-md outline-blue-400 px-3 md:w-[80%]"
              />

              <label className="text-[#4c4d56]" htmlFor="firstName">
                First Name*
              </label>
              <FormAuthenticationmessage
                type="text"
                id="firstName"
                placeholder="First Name"
                name="firstName"
                className=" py-3 border text-[#415674] text-[13px] w-[90%] border-slate-300 my-3 rounded-md outline-blue-400 px-3 md:w-[80%]"
              />

              <label className="text-[#4c4d56]" htmlFor="lastName">
                Last Name*
              </label>
              <FormAuthenticationmessage
                type="text"
                id="lastName"
                name="lastName"
                placeholder="Last Name"
                className=" py-3 border text-[#415674] text-[13px] w-[90%] border-slate-300 my-3 rounded-md outline-blue-400 px-3 md:w-[80%]"
              />

              <label className="text-[#4c4d56]" htmlFor="jobTitle">
                Job Title*
              </label>
              <FormAuthenticationmessage
                type="text"
                id="jobTitle"
                name="jobTitle"
                placeholder="Job Title"
                className=" py-3 border text-[#415674] text-[13px] w-[90%] border-slate-300 my-3 rounded-md outline-blue-400 px-3 md:w-[80%]"
              />

              <label className="text-[#4c4d56]" htmlFor="telephoneNumber">
                Telephone Number*
              </label>
              <FormAuthenticationmessage
                type="text"
                id="telephoneNumber"
                placeholder="Telephone Number"
                name="telephoneNumber"
                className=" py-3 border text-[#415674] text-[13px] w-[90%] border-slate-300 my-3 rounded-md outline-blue-400 px-3 md:w-[80%]"
              />

              <label className="text-[#4c4d56]" htmlFor="emailAddress">
                Email{" "}
              </label>
              <FormAuthenticationmessage
                type="text"
                id="emailAddress"
                placeholder="Email"
                name="emailAddress"
                className=" py-3 border text-[#415674] text-[13px] w-[90%] border-slate-300 my-3 rounded-md outline-blue-400 px-3 md:w-[80%]"
              />
</div>
              {/* radios   */}
              <h1 className="mt-20 bg-white shadow-black/50 w-fit px-4 md:px-12 mx-auto max-h-fit my-2   py-4 md:h-16 flex items-center justify-center text-center shadow-lg  rounded-lg  font-bold text-lg md:text-xl lg:text-2xl">
                What type(s) of products would you like to have certified?
              </h1>

              <div className="mt-20  grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-[14px]">
                {checkboxesdata.map((option) => (
                  <div key={option.value} className="flex items-center mb-4">
                    <input
                      type="checkbox"
                      id={`checkbox-${option.value}`}
                      name="checkboxes"
                      value={option.value}
                      className="mr-2"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.checkboxes.includes(option.value)}
                    />
                    <label htmlFor={`checkbox-${option.value}`}>
                      {option.label}
                    </label>
                  </div>
                ))}
              </div>
              <h1 className="mt-12 w-fit px-4 md:px-12 mx-auto max-h-fit my-2 md:h-16 flex items-center justify-center text-center shadow-lg bg-white shadow-black/50 rounded-lg  font-bold text-lg md:text-xl lg:text-2xl">
                Almost done! Two More Question
              </h1>
              <p className="font-light text-[14px] text-gray-400 pb-2 pt-10">
                Please describe your current certification status:
              </p>
              <div className="">
                <label htmlFor="First " className="flex my-3">
                  <FormAuthenticationmessage
                    type="radio"
                    id="certifiedTimes1"
                    name="certifiedTimes"
                    value="First"
                  />
                  This Is Our First Application
                </label>
                <label htmlFor="Other_Company" className="flex mb-5">
                  <FormAuthenticationmessage
                    type="radio"
                    id="certifiedTimes2"
                    name="certifiedTimes"
                    value="Other_Company"
                  />
                  Our Company Has Already Been Certified By Another Party
                </label>

                <div className="form-group">
                  <label className="text-[#4c4d56] " htmlFor="state">
                    Name Of The Previous Certification Body
                  </label>
                  <FormAuthenticationmessage
                    type="text"
                    id="certificationbody"
                    name="certificationbody"
                    className=" py-3 border text-[#415674] text-[13px] w-[90%] md:w-[60%]  border-slate-300 my-3 rounded-md outline-blue-400 px-3 "
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="text-[#4c4d56]">
                  Has you certificate suspended/withdrawn before?
                </label>
                <div className="flex items-center space-x-2 py-3">
                  <label className="flex items-center">
                    <FormAuthenticationmessage
                      type="radio"
                      name="question1"
                      value="Yes"
                      className="mr-2"
                    />
                    Yes
                  </label>
                  <label className="flex items-center">
                    <FormAuthenticationmessage
                      type="radio"
                      name="question1"
                      value="No"
                      className="mr-2"
                    />
                    No
                  </label>
                </div>
              </div>

              <div className="">
                <label className="text-[#4c4d56] ">
                  Would you like to transfer your registration to Control Union?
                </label>
                <div className="flex items-center space-x-2 py-3">
                  <label className="flex items-center">
                    <FormAuthenticationmessage
                      type="radio"
                      name="question2"
                      value="Yes"
                      className="mr-2"
                    />
                    Yes
                  </label>
                  <label className="flex items-center">
                    <FormAuthenticationmessage
                      type="radio"
                      name="question2"
                      value="No"
                      className="mr-2"
                    />
                    No
                  </label>
                </div>
              </div>

              <div className="">
                <label className="text-[#4c4d56]">
                  Whether another Certification Body has denied certification?
                </label>
                <div className="flex items-center space-x-2 py-3">
                  <label className="flex items-center">
                    <FormAuthenticationmessage
                      type="radio"
                      name="question3"
                      value="Yes"
                      className="mr-2"
                    />
                    Yes
                  </label>
                  <label className="flex items-center">
                    <FormAuthenticationmessage
                      type="radio"
                      name="question3"
                      value="No"
                      className="mr-2"
                    />
                    No
                  </label>
                </div>
              </div>

              <div className="">
                <label className="text-[#4c4d56]">
                  Are you already a Contol Union certified client (Is it your
                  re-certfification with Control Union) ?
                </label>
                <div className="flex items-center space-x-2 py-3 ">
                  <label className="flex items-center">
                    <FormAuthenticationmessage
                      type="radio"
                      name="question4"
                      value="Yes"
                      className="mr-2"
                    />
                    Yes
                  </label>
                  <label className="flex items-center">
                    <FormAuthenticationmessage
                      type="radio"
                      name="question4"
                      value="No"
                      className="mr-2"
                    />
                    No
                  </label>
                </div>
              </div>

              <div className="">
                <label className="text-[#4c4d56]">
                  Are you a member of any of the founding organizations of GOTS?
                </label>
                <div className="flex items-center space-x-2 py-3">
                  <label className="flex items-center">
                    <FormAuthenticationmessage
                      type="radio"
                      name="question5"
                      value="Yes"
                      className="mr-2"
                    />
                    Yes
                  </label>
                  <label className="flex items-center">
                    <FormAuthenticationmessage
                      type="radio"
                      name="question5"
                      value="No"
                      className="mr-2"
                    />
                    No
                  </label>
                </div>
              </div>

              <div className="form-group">
                <label className="text-[#4c4d56]">
                  Which Control Union office would be your preferred partner?
                </label>
                <br />
                <FormAuthenticationmessage
                  as="select" // Use "as" prop to specify the element type
                  name="selectedCountry"
                  className="py-3 border text-[#415674] text-[13px] w-[90%] border-slate-300 my-3 rounded-md outline-blue-400 px-3 md:w-[60%]"
                >
                  <option value="" disabled>
                    Select a country
                  </option>
                  {countryOptions.map((country, index) => (
                    <option key={index} value={country}>
                      {country}
                    </option>
                  ))}
                </FormAuthenticationmessage>
              </div>
              <div className="my-12 flex items-center space-x-4">
          <FormAuthenticationmessage
            type="checkbox"
            name="acceptPolices"
            className="w-5 h-5"
            label="I Accept The Validity And Authenticity Of Above Information In Application Form"
            checked={formik.values.acceptPolices}
          />
        </div>
              <div className="my-12 ">
                <button
                  type="submit"
                  className="btn bg-orange-500  hover:shadow-xl transition-all duration-200 ease-out text-white cursor-pointer mt-3 px-5  md:px-8 py-2 hover:bg-orange-600 rounded-md text-xl "
                  
                  
                >
                  Submit
                </button>
                <button
                  className="btn ml-3 md:mx-10 hover:shadow-xl bg-gray-500 transition-all duration-200 ease-out text-white cursor-pointer mt-3 px-5 md:px-8 py-2 rounded-md text-xl "
                  type="reset"
                  onClick={handleLinkClick}
                >
                  Reset
                </button>
              </div>
            </div>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};
